var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c("span", _vm._g(_vm._b({}, "span", attrs, false), on), [
                _vm._v(
                  " " +
                    _vm._s(_vm.wallet ? _vm.formatWallet(_vm.wallet) : "-") +
                    " "
                ),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", "x-small": "" },
                  on: { click: _vm.copyTextToClipboard },
                },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v(" mdi-content-copy "),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.wallet ? _vm.wallet : "пустое значение"))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }