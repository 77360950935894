var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.status === "created"
    ? _c("v-chip", { attrs: { color: "primary" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "canceled"
    ? _c("v-chip", { attrs: { color: "gray" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "offer"
    ? _c("v-chip", { attrs: { color: "orange" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "accepted"
    ? _c("v-chip", { attrs: { color: "green" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "proof"
    ? _c("v-chip", { attrs: { color: "blue" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "completed"
    ? _c("v-chip", { attrs: { color: "green" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "Pending"
    ? _c("v-chip", { attrs: { color: "gray" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "Processing"
    ? _c("v-chip", { attrs: { color: "orange" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "Success"
    ? _c("v-chip", { attrs: { color: "green" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _vm.item.status === "Canceled"
    ? _c("v-chip", { attrs: { color: "red" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
    : _c("v-chip", { attrs: { color: "brown" } }, [
        _vm._v(" " + _vm._s(_vm.item.status)),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }