<template>

  <div>
    <v-select
        v-model="value2"
        label="Выбор карты"
        :items="cards"
        :item-text="item => item.id + ' - ' + item.number + ' - ' + item.owner "
        return-object
    ></v-select>
  </div>

</template>

<script>

//-------------------------------------------------------------------------------------------------------
//WARNING initId  must be "0"   or   valid cardid!!!
//-------------------------------------------------------------------------------------------------------


import gql from "graphql-tag";

export default {
  name: 'CardSelectWithInactive',

  data: function () {
    return {

      value2: null,
      cards: null,

      //initCardId:0,
      initState:true,
    }
  },

  props: ['tag', 'value', 'caption','initId'],

  watch: {
    value2: function (val) {
    //  if (!this.initState)
        this.$emit('selected', val.number, val)
    }
  },


  apollo: {

      cards: {
        query: gql`
        query c {
  cards(where: {  deletedAt: {_is_null: true }  }, order_by: {id: asc}) {
    id
    number
    owner
    status
    deletedAt
    chatId
    tg
  }
}
`,
        result({data}) {
          this.cards = data.cards;

          if (this.initState){  //select initial element

            let init_id = parseInt(this.initId)

            if ( init_id === 0 )
              return

            this.cards.forEach( c => {
              if (c.id === init_id ) {
                this.value2 = c
              }
            })

            this.initState = false
          }

        },
      },
    },



}
</script>
