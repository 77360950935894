<template>
 <span>{{ new Date(Date.parse(value)).toLocaleString() }}</span>
</template>

<script>
export default {
  name: "DateTimeCell",

  props: [ 'value'],
}
</script>

<style scoped>

</style>