<template>

  <v-list dense>

    <v-list-item  class="mb-10">
      <v-list-item-action>
        <v-img
            max-height="220"
            max-width="132"
            :src="myImage"
        ></v-img>

      </v-list-item-action>
      <v-list-item-content>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>

      <v-list-item-content>
        <v-list-item-title>{{role}}</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>


    <v-list-item>

      <v-list-item-content>
        <v-list-item-title>Баланс: {{ toFixed2(bal) }} ₽</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>

    <v-list-item>

      <v-list-item-content>
        <v-list-item-title>Баланс выплат:  {{ toFixed2(pbal) }} Р</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>




    <v-list-item link v-for="(item, index) in sidePanel" :key="index" :to="item.route">
      <v-list-item-action>
        <v-icon>{{item.icon}}</v-icon>
        <v-badge v-if="item.badge"
                 color="red"
                 :content="item.badge"></v-badge>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title v-text="item.text"></v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :href="getBrand()" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-file-code</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Документация</v-list-item-title>

      </v-list-item-content>
    </v-list-item>





    <v-list-item link  @click="logout">
      <v-list-item-action>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Выйти</v-list-item-title>

      </v-list-item-content>
    </v-list-item>




  </v-list>

</template>

<script>
 // import router from "../../router";

  export default {
    name: 'MerchantMenu',

    data: () => ({

      myImage: require('@/logo.svg'),

      role:"Роль: Merchant",

      sidePanel: [
        {text: 'Транзакции', icon: 'mdi-cash-sync', route: '/transactions'},
        {text: 'Выводы', icon: 'mdi-hand-coin', route: '/withdraws'},
        {text: 'Выплаты', icon: 'mdi-cash-minus', route: '/payouts'},
        {text: 'Настройки', icon: 'mdi-cog', route: '/config'},
      ],

    }
    ),


    methods: {

      getBrand(){
        return process.env.VUE_APP_BRAND;
      },

      logout() {
        this.$store.dispatch('auth/onLogout');
      },


      toFixed2(num) {
        return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      },

    },

    computed: {

      bal() {
        return this.$store.state.user.balance
      },

      pbal() {
        return this.$store.state.user.payoutBalance
      },
    }



  }
</script>
