var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.isLoggedIn
        ? _c(
            "v-app-bar",
            { staticClass: "indigo", attrs: { app: "", dark: "", dense: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.drawer = !_vm.drawer
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "" } },
                [_c("v-icon", [_vm._v("mdi-account-circle")])],
                1
              ),
              _vm.isMerchant
                ? _c(
                    "div",
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.username) +
                            " - " +
                            _vm._s(_vm.terminal) +
                            " Terminal "
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.username) +
                            " - " +
                            _vm._s(_vm.country) +
                            " - административный модуль " +
                            _vm._s(_vm.getBrand()) +
                            ". "
                        ),
                      ]),
                    ],
                    1
                  ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "indigo",
                  attrs: { href: _vm.getSupport(), target: "_blank" },
                },
                [
                  _vm._v(" Поддержка 24/7 "),
                  _c("v-icon", [_vm._v("mdi-send-circle")]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.logout } },
                [_c("v-icon", [_vm._v("mdi-logout")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isLoggedIn
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", dense: "", clipped: "", temporary: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _vm.isSuperAdmin ? _c("AdminMenu") : _vm._e(),
              _vm.isSuperOperator ? _c("SuperOperatorMenu") : _vm._e(),
              _vm.isOperator ? _c("OperatorMenu") : _vm._e(),
              _vm.isMerchant ? _c("MerchantMenu") : _vm._e(),
              _vm.isExchangeOperator ? _c("ExchangeOperatorMenu") : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }