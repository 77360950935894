var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "indigo ma-3", attrs: { text: "" } }, on),
                [_vm._v(" Заказать вывод")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v("Заказать вывод"),
          ]),
          _c(
            "v-card-text",
            [
              _vm._v(" Кошелек получения :"),
              _c("br"),
              _vm._v(" Валюта: " + _vm._s(_vm.wallet.currency)),
              _c("br"),
              _vm._v(" Сеть: " + _vm._s(_vm.wallet.network)),
              _c("br"),
              _vm._v(" Адрес: "),
              _c("WalletView", { attrs: { wallet: _vm.wallet.address } }),
              _c("br"),
              _c("hr"),
              _vm._v(" Текущий баланс: " + _vm._s(_vm.balance) + " "),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Сумма",
                                  outlined: "",
                                  clearable: "",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.sum,
                                  callback: function ($$v) {
                                    _vm.sum = $$v
                                  },
                                  expression: "sum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }