<template>
  <div>
    <span>Кошельки</span>
    <v-container fluid class="d-flex flex-wrap">

      <div class="ma-1" v-for="item in withdrawalAddresses" :key="item.id">

        <v-card
            class="mx-auto"
            max-width="344"
            outlined
        >

          <v-card-title>Валюта: {{item.currency }}  </v-card-title>
          <v-card-subtitle>Сеть: {{item.network}}</v-card-subtitle>



          <v-card-text>Адрес:

            <WalletView  :wallet="item.address"  ></WalletView>

            <br></v-card-text>

          <v-card-actions>

            <WithdrawEditDialog  :wallet="item"  v-on:ok="withdrawnew"  ></WithdrawEditDialog>

          </v-card-actions>
        </v-card>

      </div>

    </v-container>
  </div>
</template>

<script>

import gql from "graphql-tag";
import WithdrawEditDialog from "./dialogs/WithdrawEditDialog.vue";
import WalletView from "./controls/WalletView.vue";

export default {
  name: "UserWallets",

  components: {WalletView, WithdrawEditDialog },

  data: () => ({
    withdrawalAddresses: []
  }),

  computed: {
    id() {
      return this.$store.state.user.id
    },
  },

  methods:{
    withdrawnew(wallet, sum){
      this.$emit('withdrawnew', wallet, sum)
    }
  },

  apollo: {
    $subscribe: {
      withdrawalAddresses: {
        query: gql`
subscription s($id: Int!) {
  withdrawalAddresses(where: {merchantId: {_eq: $id}}) {
    address
    currency
    id
    network
  }
}
`,

        variables() {
          return {
            id: this.id,
          }
        },

        result({data}) {
          this.withdrawalAddresses = data.withdrawalAddresses;
        },
      },
    },
  },

}
</script>

