<template>

  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-10">
      <h3>Выводы - Мерчант</h3>
    </div>

    <div>
      <UserWallets :payout-addresses="payoutAddresses" v-on:withdrawnew="withdrawnew"></UserWallets>
    </div>

    <div>
      <WalletEditDialog mode="new" v-bind:item="emptyItem" v-on:ok="create"></WalletEditDialog>
    </div>

    <hr>

    <div>
      <DatePeriodPicker caption="Период выборки" :from="from" :to="to" v-on:changed="onPerodChanged"></DatePeriodPicker>
    </div>

    <div>
      <v-data-table
          dense
          :items="withdrawals"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <StatusCell :item="item"/>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <DateTimeCell :value="item.createdAt"></DateTimeCell>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <DateTimeCell :value="item.updatedAt"></DateTimeCell>
        </template>
        <template v-slot:item.amount="{ item }">
          <FinanceCell :value="item.amount"/>
        </template>

        <template v-slot:item.withdrawalAddressCurrency="{ item }">
          {{ item.withdrawalAddress ? item.withdrawalAddress.currency : "--" }}
        </template>

        <template v-slot:item.withdrawalAddressAddress="{ item }">
          <WalletView v-if="item.withdrawalAddress" :wallet="item.withdrawalAddress.address"></WalletView>
          <span v-else>--</span>
        </template>

        <template v-slot:item.withdrawalAddressNetwork="{ item }">
          {{ item.withdrawalAddress ? item.withdrawalAddress.network : "--" }}
        </template>

        <template v-slot:item.userUsername="{ item }">{{ item.user ? item.user.username : "--" }}</template>
        <template v-slot:item.actions="{ item }">

          <v-btn class="red" v-if="item.status==='Pending'  " dense text
                 @click="del(item)">Отменить
          </v-btn>

          <v-btn class="blue" v-if="item.status==='Success'  " dense text target="_blank"
                 :href="getDownloadLink(item.proof)">Скачать чек
          </v-btn>


        </template>


      </v-data-table>
    </div>
  </div>

</template>

<script>

import gql from "graphql-tag";
import StatusCell from "./controls/StatusCell.vue";
import FinanceCell from "./controls/FinanceCell.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";
import WalletEditDialog from "./dialogs/WalletEditDialog.vue";
import {getHeaders} from "../store";
import UserWallets from "./UserWallets";
import WalletView from "./controls/WalletView.vue";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_MERCHANT + "/withdrawal"

import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";

import DatePeriodPicker from "./controls/DatePeriodPicker.vue";

export default {
  name: 'WithdrawsUser',
  components: {
    UserWallets,
    DateTimeCell,
    FinanceCell,
    StatusCell,
    WalletEditDialog,
    Snackbar,
    DatePeriodPicker,
    WalletView
  },
  apollo: {
    $subscribe: {
      withdrawals: {
        query: gql`
subscription s($status: [String!] ,$from: timestamptz!, $to: timestamptz! ) {
  withdrawals(

  where:
  {status: {_in: $status},

  _and:  {updatedAt: {_gte: $from, _lte: $to}

  }
  }

  ) {


    id
    amount
    createdAt
    updatedAt
    user {
      id
      username
      status
    }

    proof

    status

    withdrawalAddress {
      address
      currency
      network
    }

  }
}
`,

        variables() {
          return {
            from: this.from,
            to: this.to,
            status: ["Pending", "Processing", "Success"],
          }
        },

        result({data}) {
          this.withdrawals = data.withdrawals;
        },
      },
    },
  },

  data: () => ({

    from: "2024-01-01",
    to: "2024-12-31",

    filterByDatePeriod: false,
    withdrawals: [],


    emptyItem: {
      address: "",
      net: "",
    },

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},

      {text: 'Время создания', value: 'createdAt'},
      {text: 'Время обновления', value: 'updatedAt'},

      {text: 'Cумма', value: 'amount'},
      {text: 'Валюта', value: 'withdrawalAddressCurrency'},
      {text: 'Кошелек', value: 'withdrawalAddressAddress'},
      {text: 'Сеть', value: 'withdrawalAddressNetwork'},

      {text: 'Действия', value: 'actions'},

    ],
  }),

  computed: {
    payoutAddresses() {
      return this.$store.state.user.payoutAddresses
    },
  },

  methods: {




    getDownloadLink(image) {
      //console.log(image);
      if (image === null)
        return;

      return `${process.env.VUE_APP_FILES_BASE}/proof/withdrawal/${image.name}?type=${image.mimetype}`

    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async del(item) {


      /*

отменить выплату
app.delete("/api/merchant/payout/:id");
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body:
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async withdrawnew(wallet, sum) {


      /*
создать выплату
app.post("/api/merchant/payout");
	req.body.amount,  (float)
	req.body.addressId (int)

отменить выплату
app.delete("/api/merchant/payout/:id");
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              addressId: wallet.id,
              amount: sum,

            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async create(item) {
      //console.log(item);

      if (!item.address) {

      alert("не указан адрес")
      return;
    }

      /*
      добавить адрес выплаты
      app.post("/api/merchant/payout/address");
      req.body.address (string)
      req.body.currency (string) ( пока отправляем "USDT", позже будут еще варианты)
      req.body.network (string) (db.USDT_NETWORKS = ["TRX (TRC-20)", "Ethereum (ERC-20)"];)
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              address: item.address,
              currency: 'USDT',
              network: item.network
            })
      };

      let url = `${RestApiUrl}/address`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async finish(id, file) {
      //console.log(id, file);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)
  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */

      const formData = new FormData();
      formData.append('status', 'Success')
      formData.append('doc', file)

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data; boundary="----fucked boundary"'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)


    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
