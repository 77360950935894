<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Банковские карты - {{getBrand()}} payments</h3>
    </div>


    <v-container fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <CardsEditDialog mode="new" v-bind:item="emptyItem" v-on:ok="save"></CardsEditDialog>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <ChatIDInput  v-bind:text.sync="chatId"  ></ChatIDInput>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="70" width="300">
        <CurrencySelect  v-on:changed="currencychanged"> </CurrencySelect>
      </v-card>

    </v-container>


    <div>
      <v-data-table
          :items="cards"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.number="{ item }"> {{ item.number }}</template>
        <template v-slot:item.bankId="{ item }"> {{ item.bank ? item.bank.name : '-' }}</template>
        <template v-slot:item.country="{ item }"> {{ item.bank ? item.bank.country : '-' }}</template>
        <template v-slot:item.owner="{ item }"> {{ item.owner }}</template>



        <template v-slot:item.chatId="{ item }"> {{ item.chatId }}</template>
        <template v-slot:item.min="{ item }"> {{ item.min }}</template>
        <template v-slot:item.max="{ item }"> {{ item.max }}</template>
        <template v-slot:item.status="{ item }">
          {{ item.status ? "Активна" : "Выключена" }}

          <v-btn dense class="indigo" text @click="setActive(item, !item.status)">{{
              item.status ? "Откл" : "Вкл"
            }}
          </v-btn>

        </template>

        <template v-slot:item.actions="{ item }">
          <CardsEditDialog caption="правка" mode="edit" v-bind:item="item" v-on:ok="save"></CardsEditDialog>

          <v-btn class="red" dense text   @click="del(item)">Удалить</v-btn>



        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>

import CardsEditDialog from '../components/dialogs/CardsEditDialog.vue'
import gql from "graphql-tag";
import {getHeaders} from "../store";

import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
import CurrencySelect from "../components/controls/CurrencySelect.vue";
import ChatIDInput from "../components/controls/ChatIDInput.vue";
const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/card"

export default {
  name: 'Cards',

  components: {ChatIDInput, CurrencySelect, CardsEditDialog, Snackbar},

  apollo: {
    $subscribe: {
      cards: {
        query: gql`subscription ($exp: cards_bool_exp! = {} )  {
              cards(where: {_and:[  {deletedAt: {_is_null: true}},  $exp ]} ) {
    comment

    id
    limit
    max
    min
    number
    owner
    status


    chatId

    bankId

    bank {
      id
      name
      country
    }

    tg

  }
 }`,// bankId - need!!!

        variables() {
          let id_exp = this.chatId ? {chatId: {_eq: this.chatId}} : {};

          let country_exp = this.country ? {bank: {country: {_ilike: `%${this.country}%`}}} : {}

          let exp = {_and: [id_exp,country_exp]}
          return {
            exp: exp,
          }
        },

        result({data}) {
          this.cards = data.cards;
        },
      },
    },
  },

  data: () => ({

    cards: [],
    country:"",

    chatId:0,

    emptyItem: {
      number: "",
      bankId: "",
      owner: "",
      limit: "",
      min: "",
      max: "",
      comment: "",
      tg: {id: "", link: ""},
      chatId:0,
      //excludedChats:[]
    },

    headers: [
      {text: 'id', value: 'id'},
      {text: 'Номер карты', value: 'number'},
      {text: 'Банк', value: 'bankId'},
      {text: 'Страна', value: 'country'},
      {text: 'Держатель', value: 'owner'},
     // {text: 'Баланс', value: 'balance'},
    //  {text: 'Дневной лимит', value: 'limit'},
     // {text: 'Мин. сумма', value: 'min'},
     // {text: 'Макс. сумма', value: 'max'},
      {text: 'Ид чата', value: 'chatId'},
      {text: 'Коммент.', value: 'comment'},
      {text: 'Активна', value: 'status'},
      {text: 'Действия', value: 'actions'},




    ],

  }),

  methods: {

    currencychanged(value){
      this.country = value===null ? "" :  value.country

    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    getBrand(){
      return process.env.VUE_APP_BRAND;
    },

    setActive(item, active) {
      item.status = active
      this.save(item, "edit")
    },

    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async drop(item) {

      //PUT /api/admin/card/:id

      if (!window.confirm(`Сбросить счетчик баланса карты? \n ID: ${item.id};
        Номер: ${item.number};
        Владелец: ${item.owner };
        Банк: ${item.bank ? item.bank.name : '-'};


            `)) {

        return
      }



      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method:  'PUT',
        headers: headers,
        // body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async del(item) {


      if (!window.confirm(`УДАЛИТЬ карту !? \n ID: ${item.id};
        Номер: ${item.number};
        Владелец: ${item.owner };
        Банк: ${item.bank ? item.bank.name : '-'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method:  'DELETE',
        headers: headers,
       // body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
