<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo" dense text v-on="on"> {{ caption }}</v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Карта</v-card-title>

      <div>
        <v-form>
          <v-container>

            <v-select
                v-model="value2"
                label="Выбор карты"
                :items="cards"
                :item-text="item => item.number + ' - ' + item.owner "
                return-object
            ></v-select>


          </v-container>
        </v-form>
      </div>

      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn

            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>

    </v-card>

  </v-dialog>

</template>


<script>

//  import Card from "@/models/Card"
import gql from "graphql-tag";


export default {
  name: 'CardSelectDialog',

  data: function () {
    return {
      dialog: false,
      value2: {},
      cards: null
    }
  },

  props: ['tag', 'value', 'caption'],

  watch: {
    value2: function (val) {
      console.log(val?.number)
    }
  },

  methods: {

    onClickAppend() {
      //this.value2 = null
    },


    ok: function () {
      if (!this.value2) {
        alert('Вначале нужно ввести!')
        return;
      }

      this.dialog = false;
      this.$emit('ok', this.tag, this.value2)
    }
  },

  async mounted() {
    this.value2 = this.value

  },

  apollo: {

    //$subscribe: {


      cards: {
        query: gql`
        query c {
  cards(where: {status: {_eq: true}, _and: {deletedAt: {_is_null: true}}}, order_by: {id: asc}) {
    id
    number
    owner
    status
    deletedAt
  }
}
`,
        result({data}) {
          this.cards = data.cards;
        },
      },
    },
  //},


}
</script>
