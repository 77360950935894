<template>

  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-10">
      <h3>Выводы - Админ</h3>
    </div>

    <div>
      <v-data-table
          dense
          :items="withdrawals"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">   <StatusCell :item="item"/>  </template>
        <template v-slot:item.updatedAt="{ item }">  <DateTimeCell :value="item.updatedAt"></DateTimeCell></template>
        <template v-slot:item.amount="{ item }"> <FinanceCell :value="item.amount"/>  </template>
        <template v-slot:item.payoutAddressCurrency="{ item }">{{ item.withdrawalAddress ? item.withdrawalAddress.currency : "--" }}  </template>
        <template v-slot:item.payoutAddressAddress="{ item }"><WalletView  :wallet="item.withdrawalAddress ? item.withdrawalAddress.address : '--'" > </WalletView></template>
        <template v-slot:item.payoutAddressNetwork="{ item }">{{ item.withdrawalAddress ? item.withdrawalAddress.network : "--" }}  </template>
        <template v-slot:item.userUsername="{ item }">{{ item.user ? item.user.username : "--" }}  </template>

        <template v-slot:item.actions="{ item }">
          <v-btn class="blue" v-if="item.status==='Pending'  " dense text   @click="accept(item.id)">Принять в работу </v-btn>
          <WithdrawFinishDialog  v-if="item.status==='Processing'  "  caption="Выполнено" v-bind:tag="item.id"  v-on:ok="finish"  />
        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>


import gql from "graphql-tag";
import StatusCell from "./controls/StatusCell.vue";
import FinanceCell from "./controls/FinanceCell.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";
import WithdrawFinishDialog from "./dialogs/ImagePickerDialog.vue";
import {getHeaders} from "../store";



import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";
import WalletView from "./controls/WalletView.vue";

const RestApiUrl =  process.env.VUE_APP_REST_API_URL_ADMIN + "/withdrawal"


export default {
  name: 'WithdrawsAdmin',
  components: {WalletView, DateTimeCell, FinanceCell, StatusCell, WithdrawFinishDialog, Snackbar},
  apollo: {
    $subscribe: {
      withdrawals: {
        query: gql`
subscription s($status: [String!]) {
  withdrawals(where: {status: {_in: $status}}) {
    id
    amount
    createdAt
    updatedAt
    user {
      id
      username
      status
    }
    withdrawalAddress {
      network
      address
      currency
    }
    status
  }
}
`,

        variables() {
          return {
            status: ["Pending", "Processing"],
          }
        },

        result({data}) {
          this.withdrawals = data.withdrawals;
        },
      },
    },
  },

  data: () => ({

    from: "2021-01-01",
    to: "2023-01-01",
    filterByDatePeriod: false,
    withdrawals: [],

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Время обновления', value: 'updatedAt'},
      {text: 'Кошелек', value: 'payoutAddressAddress'},
      {text: 'Сеть', value: 'payoutAddressNetwork'},
      {text: 'Cумма', value: 'amount'},
      {text: 'Валюта', value: 'payoutAddressCurrency'},
      {text: 'Мерчант', value: 'userUsername'},
      {text: 'Действия', value: 'actions'},
    ],
  }),


  methods: {

dosnackbar(text) {
  this.$refs.snackbar.showit(text)
},

    async accept(id){

      //console.log(id);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)
  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */

      const formData  = new FormData();
      formData.append('status', 'Processing')

      let headers = await getHeaders()

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    accept2(id){

      //console.log(id);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)

  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */


      //formData.append('status', 'Processing')

      let headers = getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({status: 'Processing'})
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async finish(id, file){
     // console.log(id,file);

      /*
        api админу
  изменить выплату
  app.patch("/api/admin/payout/:id");  (multipart/form-data)
  req.body.status (string)    ( db.WITHDRAWAL_STATUSES = ["Pending", "Processing", "Success", "Canceled"]; )
  req.files.doc (multipart/form-data)
       */

      const formData  = new FormData();
      formData.append('status', 'Success')
      formData.append('doc', file )

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data; boundary="----fucked boundary"'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: formData
      };

      let url = `${RestApiUrl}/${id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
