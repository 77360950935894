var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { staticClass: "indigo", attrs: { dense: "", text: "" } },
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.caption))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v("Карта"),
          ]),
          _c(
            "div",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Выбор карты",
                          items: _vm.cards,
                          "item-text": (item) =>
                            item.number + " - " + item.owner,
                          "return-object": "",
                        },
                        model: {
                          value: _vm.value2,
                          callback: function ($$v) {
                            _vm.value2 = $$v
                          },
                          expression: "value2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }