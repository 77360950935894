<template>


  <v-form class="ma-5"
          style="width: 250px;">

    <v-text-field
        v-model="username"
        label="Логин"
        outlined
        clearable
    ></v-text-field>

    <v-text-field
        v-model="password"
        label="Пароль"
        outlined



        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
    ></v-text-field>

    <v-btn block @click="login"
           elevation="2"
           class="indigo"
    >Войти
    </v-btn>



  </v-form>


</template>


<script>


import store from "../store";

export default {
  data() {
    return {
      show1:false,
      username: '',
      password: '',
      submitted: false
    }
  },
  computed: {
    loggingIn() {
      return this.$store.state.user.status.loggingIn;
    }
  },

  created() {
    // reset login status
    // this.$store.dispatch('authentication/logout');
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    login() {
      this.submitted = true;
      const {username, password} = this;


      if (username && password)
        this.$store.dispatch('auth/onLogin', {username, password})
            .then(
                () => {
                  this.$router.replace(store.state.user.role==="exch_operator" ? '/exchangeUsers': '/transactions' );
                  //this.$router.push({name:"Transactions"})
                }
            )
            .catch((reason)=>{

              alert(reason.response.data.message)

            })

    }
  }

};
</script>