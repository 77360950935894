<template>
  <div class="text-center">

    <!--v-btn
        dark
        color="orange darken-2"
        @click="snackbar = true"
    >
      Open Snackbar
    </v-btn-->

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"

        app
        top

        transition="slide-y-transition"

    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Snackbar",

  data: () => ({
    snackbar: false,
    text: '---',
    timeout: 4000,
  }),

  methods:{

    showit(text){  //run externally like this.$refs.snackbar.showit(text)
      this.text=text
      this.snackbar = true
    }

  },

}
</script>

<style scoped>

</style>