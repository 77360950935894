<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3"
             text v-on="on">
        {{ mode==="new" ? "создание" : "правка"}}
      </v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Банк - {{ mode=="new" ? "создание" : "правка"}}</v-card-title>

      <div>

        <v-form>
          <v-container>
            <v-row>
              <v-col
                  cols="10"

              >
                <v-text-field
                    v-model="item2.name"
                    label="Название"

                    outlined
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model="item2.rate"
                    label="Курс"
                    type="number"
                    outlined
                    clearable
                ></v-text-field>

                <v-text-field v-if=" (typeof  item2.logo  !== 'object')  "
                    v-model="item2.logo"
                    label="Лого"

                    outlined
                    clearable
                ></v-text-field>

                <v-file-input

                    v-model="item2.logo"
                    label="Лого"
                    accept="image/svg"
                    show-size
                    truncate-length="15"

                    outlined
                    clearable

                ></v-file-input>

                <v-text-field
                    v-model="item2.sort"
                    label="Порядок сортировки"
                    type="number"
                    outlined
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model="item2.country"
                    label="Страна"

                    outlined
                    clearable
                ></v-text-field>


              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="cancel"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>




module.exports =
    {
      data: function () {
        return {
          dialog: false,

          item2:{
            status:false,
            rate:0,
            name:"",
            logo:"",
            sort:0
          },

        }
      },

      props: ['item', 'mode'],

      mounted() {

        //if (this.item !== undefined) //  or  mode new

        if (this.mode!=="new") //if new use default
          this.item2 = { ...this.item } //  clone obj  1 lvl

      },

      watch:{
     /*   dialog:function (val){

          if (val){
            if (this.mode!=="new") //if new use default
              this.item2 = { ...this.item }
          }

        } */
      },

      methods: {
        ok: function () {
          this.dialog = false;
          this.$emit('ok', this.item2, this.mode, this.item )
        },

        cancel(){
          this.dialog = false
        }
      },


    }
</script>
