<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3"
             text v-on="on">
        {{ mode==="new" ? "Создать кошелек" : "Правка кошелька"}}
      </v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Кошелек - {{ mode==="new" ? "создание" : "правка"}}</v-card-title>

      <div>

        <v-form>
          <v-container>
            <v-row>
              <v-col  cols="10" >

                <v-text-field  v-model="item.address"   label="адрес"    outlined  clearable  ></v-text-field>
                <!--v-text-field  v-model="item.currency"   label="валюта"   outlined  clearable  ></v-text-field-->
                <!--v-text-field  v-model="item.net"   label="сеть"   outlined  clearable  ></v-text-field-->

                <v-select
                    v-model="item.network"
                    label="Сеть"
                    :items="nets"
                    outlined
                    clearable
                    return-object
                ></v-select>

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>




module.exports =
    {
      name: 'WalletEditDialog',

      data: function () {
        return {
          dialog: false,
          nets:["TRX (TRC-20)", "Ethereum (ERC-20)"],
          curs:["USDT"],

        }
      },

      props: ['item', 'mode'],


      methods: {
        ok: function () {

          if (!this.item.address) {

            alert("не указан адрес")
            return;
          }


          this.dialog = false;
          this.$emit('ok', this.item, this.mode)
        }
      },


    }
</script>
