<template>
  <div>

    <History :cardId="cardId" v-if="role==='superadmin'  ||  role==='administrator' ||  role==='operator' "></History>

  </div>

</template>

<script>


import History from "../components/History.vue";

export default {

  props: ['cardId'],

  name: 'HistoryView',

  components: {
    History
  },

  data: () => ({}),

  computed: {
    role() {
      return this.$store.state.user.role
    },
  },

  methods: {},

}
</script>
