<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3" text v-on="on"> Заказать вывод</v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Заказать вывод</v-card-title>

      <v-card-text>
        Кошелек получения :<br>
        Валюта: {{ wallet.currency }}<br>
        Сеть: {{ wallet.network }}<br>
        Адрес:    <WalletView  :wallet="wallet.address"  ></WalletView><br>

        <hr>
        Текущий баланс: {{ balance }}
      </v-card-text>

      <div>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="10">
                <v-text-field
                    v-model="sum"
                    label="Сумма"
                    outlined
                    clearable
                    type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>


<script>

import WalletView from "../controls/WalletView.vue";



export default
    {

      name: 'WithdrawEditDialog',

      components: {WalletView},

      data: function () {
        return {
          dialog: false,
          sum: +0
        }
      },

      props: ['wallet'],

      methods: {
        ok: function () {

          this.dialog = false;
          this.$emit('ok', this.wallet, this.sum)
        }
      },

      formatWallet(w) {
        return  w.length < 10 ? w :   `${w.slice(0, 4)} ... ${w.slice(-4)}`
      },

      computed: {
        balance() {
          return this.$store.state.user.balance
        },
      },

      mounted() {

      },
    }

</script>
