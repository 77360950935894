var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("CardSelectWithInactive", {
            attrs: { initId: _vm.initial_id },
            on: { selected: _vm.on_card_selected },
          }),
        ],
        1
      ),
      _c("div", [
        _vm._v(
          " Владелец: " +
            _vm._s(_vm.card_selected.owner ? _vm.card_selected.owner : "--")
        ),
        _c("br"),
        _vm._v(" Чат: " + _vm._s(_vm.card_selected.chatId) + " "),
      ]),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-10",
            attrs: {
              dense: "",
              items: _vm.balanceAdjusters,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.status) + " ")]
                },
              },
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.updatedAt } }),
                  ]
                },
              },
              {
                key: "item.value",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(JSON.stringify(item.value)))]
                },
              },
              {
                key: "item.user",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.user ? item.user.username : "--")),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions mt-10" }, [
      _c("h3", [_vm._v("История корректировок")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }