<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <DatePeriodPicker caption="Период выборки" :from="from" :to="to" v-on:changed="onPerodChanged"></DatePeriodPicker>


    <v-container fluid class="d-flex flex-wrap pa-0 mt-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="130" width="300">
        <span><strong>Поиск по ID</strong> - только полное соответствие. </span>

        <v-text-field
            v-model="id"
            label="ID"
            outlined
            clearable
            type="number"
            max="2147483647"
            oninput="validity.valid||(value='');"
        ></v-text-field>
      </v-card>

      <v-card class="pa-2  mb-2 mr-2" height="130" width="300">
        Итого суммы по текущей странице:<br>
        <span>{{ sum.toLocaleString('ru-RU') }} в валюте терминала </span>

        <!--span v-if="terminal==='RUB'">{{ sumRub.toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'}) }}</span>
        <span v-if="terminal==='USD'">{{ sumUsd.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}) }}</span-->
        <br>{{ sumUsdt.toLocaleString('ru-RU') }} USDT <br>
        <span class="font-weight-thin">Суммируется только completed по текущей странице.</span>
      </v-card>


      <v-card class="pa-2  mb-2 mr-2" height="130" width="300">
        <span> Скачать CSV по выборке в кодировке:</span><br>
        <v-btn class="ma-1" v-on:click="csvRequest(false)">UTF8</v-btn>
        <v-btn class="ma-1" v-on:click="csvRequest(true)">Win1251</v-btn>
      </v-card>


      <v-card class="pa-2  mb-2 mr-2" height="130" width="300">
        <span> Отфильтровать:</span><br>

        <v-radio-group row dense v-model="radios">
          <v-radio label="Только успешные" value="1"></v-radio>
          <v-radio label="Все" value="2"></v-radio>
          <v-radio label="Только отмены" value="3"></v-radio>
        </v-radio-group>

      </v-card>

    </v-container>


    <div>
      <v-data-table

          hide-default-footer

          disable-pagination

          dense
          :items="transactions"
          :headers="computedHeaders"
          class=" mt-1"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[  50,  100,  200, 100000 ,
500000,
1000000, -1]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <StatusCell :item="item"/>
        </template>
        <template v-slot:item.updatedAt="{ item }"> {{
            new Date(Date.parse(item.updatedAt)).toLocaleString()
          }}
        </template>
        <template v-slot:item.amount="{ item }"> {{
            item.amount.toLocaleString('ru-RU', {
              style: 'currency',
              currency: terminal
            })
          }}
        </template>

        <template v-slot:item.currency="{ item }"> {{ item.bank ? get$ByCountry2(item) : "" }}</template>

        <template v-slot:item.offer="{ item }"> {{
            item.offer.amount.toLocaleString('ru-RU', {
              style: 'currency',
              currency: "RUB"
            })
          }}
        </template>
        <template v-slot:item.usdt="{ item }"> {{ usdt(item).toLocaleString('ru-RU') }}</template>
        <template v-slot:item.bank="{ item }"> {{ item.bank ? item.bank.name : '--' }}</template>
        <template v-slot:item.bankRate="{ item }"> {{ item.offer ? item.offer.rate : "--" }}</template>
        <template v-slot:item.uid="{ item }"> {{ item.uid }}</template>

      </v-data-table>


      <div class="text-center pt-2">


        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="15"
        ></v-pagination>

        <div class="d-flex justify-center ma-2">
          <span class="mr-1 mt-1"> Показывать на странице: </span>
          <v-select
              style="max-width: 120px"
              dense
              v-model="itemsPerPage"
              :items="Pages"
              return-object

          ></v-select>
          <span class="ml-1  mt-1"> строк из {{ totalRows }}</span>
        </div>

      </div>

    </div>
  </div>

</template>
<script>

import gql from "graphql-tag";
import StatusCell from "./controls/StatusCell.vue";
import DatePeriodPicker from "./controls/DatePeriodPicker.vue";
import {getHeaders} from "../store";
import Snackbar from "./controls/Snackbar.vue";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_MERCHANT //+ "/transaction"

export default {
  name: 'TransactionsUsd',

  components: {StatusCell, DatePeriodPicker, Snackbar},

  apollo: {
    $subscribe: {
      transactions: {
        query: gql`
subscription s($from: timestamptz!, $to: timestamptz! , $exp: transactions_bool_exp! = {} , $lim: Int = 50, $ofs: Int = 0 ) {
  transactions(
   where: {  _and: [ {updatedAt: {_gte: $from}},   {updatedAt: {_lte: $to}} ,  $exp]   }
   , limit: $lim, offset: $ofs, order_by: {id: desc} )
  {
    id
    updatedAt
    uid
    merchantId
    status
    offer
    createdAt

    orderId

    amount
    card {
      number
    }
    bank {
      name
      country
    }
  }
}

`,

        variables() {

         /* let id_exp = this.id ? {id: {_eq: this.id}} : {};

          let status_exp = {}
          switch (this.radios) {
            case "1":
              status_exp = {status: {_eq: "completed"}}
              break
            case "3":
              status_exp = {status: {_eq: "canceled"}}
              break

            default:
              status_exp = {}
          }*/

          let exp = this.getExp() //{_and: [id_exp, status_exp]}

          return {
            from: this.from,
            to: this.to,

            lim: this.itemsPerPage,
            ofs: (this.page - 1) * this.itemsPerPage,

            exp: exp,
          }
        },

        result({data}) {

          this.transactions = data.transactions;
          this.computeSumm();
        },

      },
      transactions_aggregate: {
        query: gql`

subscription s($from: timestamptz!, $to: timestamptz! , $exp: transactions_bool_exp! = {}  ) {
    transactions_aggregate(
      where: {  _and: [ {updatedAt: {_gte: $from}},   {updatedAt: {_lte: $to}} ,  $exp] }
    ) {
    aggregate {
      count
    }
  }
}

`,

        variables() {
          let exp = this.getExp() //{_and: [id_exp, status_exp]}

          return {
            from: this.from,
            to: this.to,

            //lim: this.itemsPerPage,
            //ofs: (this.page - 1) * this.itemsPerPage,

            exp: exp,
          }
        },

        result({data}) {
          this.totalRows = data.transactions_aggregate.aggregate.count;
        },
      }
    },
  },

  data: () => ({
    from: "2024-01-01T00:00:00.000Z", // v-date-picker  and Date.parse   only need iso 8601 format  !
    to: "2024-12-31T00:00:00.000Z",

    sum:0,
    sumRub: 0,
    sumUsd: 0,
    sumUsdt: 0,

    radios: "1",

    totalRows: 0,
    id: "",
    page: 1,
    Pages: [30, 50, 100, 1000, 10000, 100000, 500000, 1000000],
    itemsPerPage: 30,

    filterByDatePeriod: false,
    transactions: [],

  }),

  mounted() {


  },

  watch: {
    special4watch: function () {
      this.page = 1
    },
  },

  computed: {



    username() {
      return this.$store.state.user.username
    },

    computedHeaders() {

      if (this.terminal === "USD")
        return [
          {text: 'id', value: 'id'},
          {text: 'Статус', value: 'status'},
          {text: 'Время', value: 'updatedAt'},
          {text: 'Сумма ', value: 'amount'},
          {text: 'Валюта ', value: 'currency'},
          //{text: 'Предложено RUB', value: 'offer'},

          {text: 'Баланс ₽', value: 'usdt'},
          {text: 'Банк', value: 'bank'},
          {text: 'Курс банка', value: 'bankRate'},
          {text: 'uid', value: 'uid'},
          {text: 'orderId', value: 'orderId'},

        ]
      else
        return [
          {text: 'id', value: 'id'},
          {text: 'Статус', value: 'status'},
          {text: 'Время', value: 'updatedAt'},
          //{text: 'Сумма USD'  , value: 'amount'},
          //{text: '---Предложено RUB', value: 'offer'},
          {text: 'Начислено ' + this.terminal, value: 'amount'},
          {text: 'Начислено ₽', value: 'usdt'},
          {text: 'Банк', value: 'bank'},
          {text: 'Курс банка', value: 'bankRate'},
          {text: 'uid', value: 'uid'},
          {text: 'orderId', value: 'orderId'},
        ]


    },

    pageCount() {
      return this.totalRows ? Math.trunc(this.totalRows / this.itemsPerPage) + 1 : 0
    },

    special4watch() {
      return `${new Date(Date.parse(this.from)).toLocaleString()}|${new Date(Date.parse(this.to)).toLocaleString()}|${this.itemsPerPage}|${this.id}`;
    },

    terminal() {
      return this.$store.state.user.terminal
    }
    ,
  },

  methods: {

    getExp(){
      let id_exp = this.id ? {id: {_eq: this.id}} : {};

      let status_exp = {}
      switch (this.radios) {
        case "1":
          status_exp = {status: {_eq: "completed"}}
          break
        case "3":
          status_exp = {status: {_eq: "canceled"}}
          break

        default:
          status_exp = {}
      }

      return {_and: [id_exp, status_exp]}
    },

    getCsvLink() {

      const from = encodeURI(this.from)
      const to = encodeURI(this.to)
      const lim = encodeURI(this.itemsPerPage)
      const ofs = encodeURI((this.page - 1) * this.itemsPerPage)
      const id = encodeURI(this.id)

      let url = `${RestApiUrl}/transactions/csv/?from=${from}&to=${to}&lim=${lim}&ofs=${ofs}&id=${id}`

      return url

    },

    async csvRequest(win1251 = false) {
      // /api/merchant/transactions/csv

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              from: this.from,
              to: this.to,
              //  lim: this.itemsPerPage,
              //  ofs: (this.page - 1) * this.itemsPerPage,
              //  id: this.id,
              encoding: win1251 ? "win" : "utf8"
            })
      };

      let url = `${RestApiUrl}/transactions/csv`


      fetch(url, requestOptions)
          .then((response) => {

                if (response.status !== 200) {  // if error
                  response.json()
                      .then(function (data) {
                        this.dosnackbar(`Ошибка ${response.status}; ${data.message}`)
                      })
                      .catch(function (data) {
                        console.log(data)
                        this.dosnackbar(`Ошибка ${response.status}`)
                      })
                  return;
                }

                response.text().then(t => this.result(t, win1251))

              }
          )
          .catch(function (err) {
            this.dosnackbar(`Ошибка Fetch Error`)
            console.log('Fetch Error', err);
          });


    },

    result(text, win1251) {

      // if (text.)


      const iconv = require('iconv-lite');

      let blob = {}

      if (win1251) {

        const c = iconv.encode(text, 'win1251');

        blob = new Blob([c], {type: 'text/csv;charset=windows-1251;'});

      } else {
        const c = iconv.encode(text, 'utf-8');
        blob = new Blob([c], {type: 'text/csv;charset=utf-8;'});
      }


      // Создаем ссылку на объект Blob
      const url = URL.createObjectURL(blob);
// Создаем элемент <a>, который будет использоваться для инициации скачивания
      const a = document.createElement('a');
      a.href = url;
// Указываем имя файла в атрибуте download элемента <a>
      var filterString = this.special4watch
      var downloadTime = (new Date()).toLocaleString()
      var user = this.username
      var filename = (user + ";" + downloadTime + ";" + filterString)
      a.download = filename + this.getChecksum(filename) + '.csv';

// Добавляем элемент <a> в DOM дерево
      document.body.appendChild(a);
// Инициируем скачивание
      a.click();
// Удаляем элемент <a> из DOM дерева
      document.body.removeChild(a);


    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    get$ByCountry2(item) {

      return this.get$ByCountry(item.bank.country)

    },

    get$ByCountry(country) {

      switch (country) {
        case "ru" :
          return "₽"
        case "en" :
          return "$"
        case "tr" :
          return "₺"

        default:
          return "def$"
      }

    },

    csv0() {
      const json2csv = require('json2csv').parse;

      var table = []

      this.transactions.forEach(
          value => table.push(
              {
                id: value.id,
                status: value.status,
                updatedAt: new Date(Date.parse(value.updatedAt)).toLocaleString(),
                amount: this.terminal === "USD" ? value.amount.toLocaleString('ru-RU', {style: 'decimal',}) : "0",
                offer: value.offer ? value.offer.amount.toLocaleString('ru-RU', {style: 'decimal',}) : "0",
                bank: value.bank ? value.bank.name : "--",
                usdt: this.usdt(value).toLocaleString('ru-RU', {style: 'decimal',}),
                cardN: value.card ? value.card.number : "--",
                cardO: value.card ? value.card.owner : "--",
                user: value.user ? value.user.name : "--",
                uid: value.uid
              }
          )
      )

      const opts = {
        delimiter: ';',
      };

// Преобразуем данные в CSV формат
      return json2csv(table, opts)
    },


    csv(win1251 = false) {

      const iconv = require('iconv-lite');

      let blob = {}

      if (win1251) {

        const c = iconv.encode(this.csv0(), 'win1251');

        blob = new Blob([c], {type: 'text/csv;charset=windows-1251;'});

      } else {
        const c = this.csv0()
        blob = new Blob([c], {type: 'text/csv;charset=utf-8;'});
      }
// Создаем ссылку на объект Blob
      const url = URL.createObjectURL(blob);
// Создаем элемент <a>, который будет использоваться для инициации скачивания
      const a = document.createElement('a');
      a.href = url;
// Указываем имя файла в атрибуте download элемента <a>
      var filterString = this.special4watch
      var downloadTime = (new Date()).toLocaleString()
      var user = this.username
      var filename = (user + ";" + downloadTime + ";" + filterString)
      a.download = filename + this.getChecksum(filename) + '.csv';

// Добавляем элемент <a> в DOM дерево
      document.body.appendChild(a);
// Инициируем скачивание
      a.click();
// Удаляем элемент <a> из DOM дерева
      document.body.removeChild(a);
    },

    getChecksum(str) {
      const digits = str.toUpperCase().split('').map(char => char.charCodeAt(0) - 64); // Преобразуем строку букв в массив цифр
      let sum = 0;

      for (let i = digits.length - 1; i >= 0; i--) {
        let digit = digits[i];

        if ((digits.length - i) % 2 === 0) { // Удваиваем каждую вторую цифру
          digit *= 2;
          if (digit >= 10) { // Заменяем удвоенную цифру на сумму цифр
            digit = digit.toString().split('').map(Number).reduce((acc, val) => acc + val, 0);
          }
        }

        sum += digit;
      }

      const checksum = (sum % 10 === 0) ? 0 : 10 - (sum % 10); // Вычисляем контрольную сумму

      return checksum;
    },

    computeSumm() {

      // Итого сумма RUB
      let sumRub = 0;
      let sumUsd = 0;
      let sumUsdt = 0;
      // Итого сумма USD

      // Итого начислено USDT

      //  Всего транзакций

      let sum =0

      this.transactions.forEach(
          t => {
            if (t.status === 'completed') {

              //summs[this.terminal] += t.amount

              sum +=  t.amount
              //sumUsd += this.terminal === "USD" ? t.amount : 0

              sumUsdt += this.usdt(t)
            }
          }
      );

      //console.log(summs)
      this.sum = sum

      this.sumRub = sumRub
      this.sumUsd = sumUsd
      this.sumUsdt = sumUsdt

    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    },

    usdt(item) {
      if (!(item.status === 'approved' || item.status === 'completed'))
        return 0

      let val = (this.terminal === 'USD')
          ?
          (item.amount * (100 - item.offer.fee) / 100) || 0
          :
          (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0

      return val

    },

    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
