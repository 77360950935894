<template>

    <v-dialog
            v-model="dialog"
            width="700"
    >
        <template v-slot:activator="{ on }">
            <v-btn class="indigo" dense    text v-on="on">   {{caption}} </v-btn>
        </template>

        <v-card>
            <v-card-title primary-title>Загрузка файла</v-card-title>

            <div>
                <v-form >
                    <v-container>

                      <div class="ma-4" >Загрузить скриншот</div>

                      <v-file-input

                          v-model="value"
                          label="Скрин"
                          accept="image/*"
                          show-size
                          truncate-length="15"

                          outlined
                          clearable


                      ></v-file-input>


                    </v-container>
                </v-form>
            </div>

            <v-divider></v-divider>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                >
                    Отмена
                </v-btn>

                <v-btn

                        color="primary"
                        text
                        @click="ok"
                >
                    Ок
                </v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>

</template>


<script>

    export default
        {
          name: 'ImagePickerDialog',

            data: function () {
                return {
                    dialog: false,
                }
            },

            props: ['tag','value','caption'],

            methods: {
                ok: function () {
                    if (!this.value) {
                        alert('Вначале нужно ввести!')
                        return;
                    }

                    this.dialog = false;
                    this.$emit('ok', this.tag, this.value)
                }
            },




        }
</script>
