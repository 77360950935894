var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v(_vm._s(_vm.fin(_vm.value)) + " " + _vm._s(_vm.currency)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }