<template>

  <v-select
      v-model="id"
      label="Мерч"
      :items="users"

      item-value="id"
      :item-text="item => item.username "
      return-object
      outlined
      clearable
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "UserCombobox",

  data: function () {
    return {
      dialog: false,
      users:[],
      id:null
    }
  },

  apollo: {
    $subscribe: {
      users: {
        query: gql`
subscription {
  users(order_by: {balance: desc}, where: {role: {_eq: "merchant"}}) {
    id
    username
  }
}
`,
        result({data}) {

          this.users = data.users;

        },
      },
    },
  },



  watch:{
    id:function (val){
      //console.log("id=",val)

        //if (val.id){
         // console.log("val=", val )
          this.$emit('changed', val)
        //}
    },
  },




}
</script>

