<template>

  <v-select
      v-model="bankname"
      label="Банк"
      :items="Banks"

      item-value="name"
      :item-text="item => item.name "
      return-object
      outlined
      clearable
  ></v-select>

</template>

<script>

export default {
  name: "BankCombobox2",

  data: function () {
    return {
      dialog: false,
      bankname:null,

      Banks:[      {
        "id": 2,
        "name": "Тинькофф"
      },
        {
          "id": 1,
          "name": "Сбербанк"
        },
        {
          "id": 3,
          "name": "СБП"
        },

      ],
    }
  },


  watch:{
    bankname:function (val){

        //if (val.id){
          this.$emit('changed', val)
        //}
    },
  },




}
</script>

