<template>


    <v-dialog
            v-model="dialog"
            width="700"
    >
        <template v-slot:activator="{ on }">
            <v-btn class="indigo" dense    text v-on="on">   {{caption}} </v-btn>
        </template>

        <v-card>
            <v-card-title primary-title>{{ title }}</v-card-title>

            <div>
                <v-form >
                    <v-container>
                        <v-row>
                            <v-col  cols="10"  >
                                <v-text-field
                                        v-model="value0"
                                        :label="label"
                                        type="number"
                                        outlined
                                        clearable
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>

            <v-divider></v-divider>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                >
                    Отмена
                </v-btn>

                <v-btn

                        color="primary"
                        text
                        @click="ok"
                >
                    Ок
                </v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>

</template>


<script>
    module.exports =

        {

          name: 'NumberEditDialog',

            data: function () {
                return {
                    dialog: false,
                    value0:0
                }
            },

            props: ['tag','value','caption','title','label'],

            methods: {
                ok: function () {
                    if (!this.value0) {
                        alert('Вначале нужно ввести!')
                        return;
                    }

                    this.dialog = false;
                    this.$emit('ok', this.tag, this.value0)
                }
            },

            mounted() {
              this.value0 = this.value
            },
        }
</script>
