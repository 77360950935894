<template>
  <span>{{ fin(value) }} {{currency}}</span>
</template>

<script>
export default {
  name: "FinanceCell",
  props: [ 'value',"currency"],

  methods:{
    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  }
}
</script>

<style scoped>

</style>