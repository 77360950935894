var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: "Выбор карты",
          items: _vm.cards,
          "item-text": (item) =>
            item.id + " - " + item.number + " - " + item.owner,
          "return-object": "",
        },
        model: {
          value: _vm.value2,
          callback: function ($$v) {
            _vm.value2 = $$v
          },
          expression: "value2",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }