<template>
  <div>
    <TransactionsUser v-if="role==='merchant'"></TransactionsUser>
    <TransactionsAdmin v-if="role==='superadmin' || role==='administrator'" > </TransactionsAdmin>
    <TransactionsOperator  v-if="role==='operator'"></TransactionsOperator>
  </div>

</template>

<script>

import TransactionsUser from "../components/TransactionsUser";
import TransactionsAdmin from "../components/TransactionsAdmin";
import TransactionsOperator from "../components/TransactionsOperator";


export default {
  name: 'Transactions',

  components: {
    TransactionsUser, TransactionsAdmin, TransactionsOperator
  },

  data: () => ({}),

  computed: {

    role() {
      return this.$store.state.user.role
    },

  },

  methods: {},

}
</script>
