<template>
  <div class="pa-2">

    <div class="Transactions">
      <h3>Транзакции - {{getBrand()}} payments {{terminal}} </h3>
    </div>

    <div>

      <TransactionsUsd ></TransactionsUsd>
    </div>
  </div>

</template>
<script>

import TransactionsUsd from "../components/TransactionsUsd";

export default {
  name: 'Transactions',

  components: {
     TransactionsUsd
  }
  ,

  data: () => ({

  }),

  computed: {
    terminal() {
      return this.$store.state.user.terminal
    }
    ,
  }
  ,

  methods: {

    getBrand(){
      return process.env.VUE_APP_BRAND;
    },

  }






  ,

}
</script>
