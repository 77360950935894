var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "indigo ma-3", attrs: { text: "" } }, on),
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.mode == "new" ? "создание" : "правка") +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(
              "Карта - " + _vm._s(_vm.mode == "new" ? "создание" : "правка")
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Номер карты",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "number", $$v)
                                  },
                                  expression: "item.number",
                                },
                              }),
                              _c("BankCombobox", {
                                attrs: { id: _vm.item.bankId },
                                on: {
                                  "update:id": function ($event) {
                                    return _vm.$set(_vm.item, "bankId", $event)
                                  },
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Держатель",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.owner,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "owner", $$v)
                                  },
                                  expression: "item.owner",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Дневной лимит",
                                  type: "number",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.limit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "limit", $$v)
                                  },
                                  expression: "item.limit",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Мин. сумма",
                                  type: "number",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.min,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "min", $$v)
                                  },
                                  expression: "item.min",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Макс. сумма",
                                  type: "number",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.max,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "max", $$v)
                                  },
                                  expression: "item.max",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Описание",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.comment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "comment", $$v)
                                  },
                                  expression: "item.comment",
                                },
                              }),
                              _c("ChatIDInput", {
                                attrs: { text: _vm.item.chatId },
                                on: {
                                  "update:text": function ($event) {
                                    return _vm.$set(_vm.item, "chatId", $event)
                                  },
                                },
                              }),
                              _vm.item.tg
                                ? [
                                    "id" in _vm.item.tg
                                      ? _c("v-text-field", {
                                          attrs: {
                                            label: "tg id",
                                            outlined: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.item.tg.id,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item.tg, "id", $$v)
                                            },
                                            expression: "item.tg.id",
                                          },
                                        })
                                      : _vm._e(),
                                    "link" in _vm.item.tg
                                      ? _c("v-text-field", {
                                          attrs: {
                                            label: "tg link",
                                            outlined: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.item.tg.link,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item.tg, "link", $$v)
                                            },
                                            expression: "item.tg.link",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }