<template>

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <span
            v-bind="attrs"
            v-on="on"
        > {{ wallet ? formatWallet(wallet) : "-" }} </span>
      <v-btn
          icon
          x-small
        @click="copyTextToClipboard"
      >
        <v-icon x-small>
          mdi-content-copy
        </v-icon>
      </v-btn>
    </template>

    <span>{{ wallet ? wallet : "пустое значение" }}</span>
  </v-tooltip>


</template>

<script>
export default {
  name: "WalletView",

  props: ['wallet'],

  methods: {

    copyTextToClipboard() {

      const text = this.wallet

      if (!navigator.clipboard) {
        // В браузерах, не поддерживающих Clipboard API
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
      } else {
        // В браузерах с поддержкой Clipboard API
        navigator.clipboard.writeText(text);
      }
    },

    formatWallet(w) {
      return w.length < 10 ? w : `${w.slice(0, 4)} ... ${w.slice(-4)}`
    },

  }

}
</script>

<style scoped>

</style>