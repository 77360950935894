<template>
  <div>
    <TransactionsArchiveFull  v-if="role==='superadmin' || role==='administrator' " ></TransactionsArchiveFull>
    <TransactionsArchiveDay   v-if="role==='operator' " ></TransactionsArchiveDay>
  </div>
</template>

<script>

import TransactionsArchiveFull from "../components/TransactionsArchiveFull.vue";
import TransactionsArchiveDay from "../components/TransactionsArchiveDay.vue";

export default {
  name: 'TransactionsArchive',

  components: {
    TransactionsArchiveDay,
    TransactionsArchiveFull,
       },

  data: () => ({}),

  computed: {

    role() {
      return this.$store.state.user.role
    },

  },

  methods: {},

}
</script>
