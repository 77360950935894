<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3"
             text v-on="on">
        {{ mode == "new" ? "создание" : "правка" }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Карта - {{ mode == "new" ? "создание" : "правка" }}</v-card-title>

      <div>

        <v-form>
          <v-container>
            <v-row>
              <v-col
                  cols="10"

              >
                <v-text-field
                    v-model="item.number"
                    label="Номер карты"

                    outlined
                    clearable
                ></v-text-field>

                <BankCombobox v-bind:id.sync="item.bankId"></BankCombobox>

                <v-text-field
                    v-model="item.owner"
                    label="Держатель"

                    outlined
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model="item.limit"
                    label="Дневной лимит"
                    type="number"
                    outlined
                    clearable
                ></v-text-field>


                <v-text-field
                    v-model="item.min"
                    label="Мин. сумма"
                    type="number"
                    outlined
                    clearable
                ></v-text-field>

                <v-text-field
                    v-model="item.max"
                    label="Макс. сумма"
                    type="number"
                    outlined
                    clearable
                ></v-text-field>


                <v-text-field
                    v-model="item.comment"
                    label="Описание"

                    outlined
                    clearable
                ></v-text-field>



                <ChatIDInput  v-bind:text.sync="item.chatId"  ></ChatIDInput>


                <template v-if="item.tg">
                  <v-text-field v-if="'id' in item.tg"
                                v-model="item.tg.id"
                                label="tg id"

                                outlined
                                clearable
                  ></v-text-field>

                  <v-text-field v-if="'link' in item.tg"
                                v-model="item.tg.link"
                                label="tg link"

                                outlined
                                clearable
                  ></v-text-field>



                </template>


              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


import BankCombobox from "../controls/BankCombobox.vue";
import ChatIDInput from "../controls/ChatIDInput.vue";

export default {
  name: 'BankEditDialog',
  components: {ChatIDInput, BankCombobox},
  data: function () {
    return {
      dialog: false,

      emptyItem: {
        number: "",
        bankId: "",
        owner: "",
        limit: "",
        min: "",
        max: "",
        comment: "",
        tg: {id: "", link: ""},
        chatId: 0,
        //excludedChats: []
      },


    }
  },

  props: ['item', 'mode'],


  watch: {


    dialog: function (val) {
      if (val) {


        if (!this.item.tg) {
          this.item.tg = {id: "", link: ""}
        }
      }
    },
  },

  methods: {

    extractNumbersFromString: function (inputString) {
      // Используем регулярное выражение для поиска чисел в строке
      const numberRegex = /\d+/g;

      // Используем метод match() для нахождения всех чисел в строке
      const numbers = inputString.match(numberRegex);

      // Возвращаем массив чисел
      return numbers ? numbers.map(Number) : [];
    },


    ok: function () {

      if (this.item.max < 0 || this.item.min < 0) {
        alert("Ошибка! Укажите мин и макс больше или равно 0")
        return
      }

      /*if (this.item.max < this.item.min) {
        alert("Ошибка! мин больше макс")
        return
      }*/

      if (!this.item.limit || this.item.limit <0) {
        alert("Ошибка! дневой лимит меньше нуля")
        return
      }

      if (!this.item.chatId || this.item.chatId <= 0) {
        alert("Ошибка! ИД чата должен быть положительным числом")
        return
      }




      this.dialog = false;
      this.$emit('ok', this.item, this.mode)
    }
  },

}
</script>
