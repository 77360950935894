<template>

  <v-list dense>

    <v-list-item class="mb-10">
      <v-list-item-action>
        <v-img
            max-height="220"
            max-width="132"
            :src="myImage"
        ></v-img>

      </v-list-item-action>
      <v-list-item-content>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-action>

      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Роль: {{ role }}</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>


    <v-list-group
        v-for="item0 in sidePanel"
        :key="item0.text"
        no-action
        :prepend-icon="item0.icon"
    >

      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title v-text="item0.text"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item link v-for="(item, index) in item0.items" :key="index" :to="item.route">

        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
          <v-badge v-if="item.badge"
                   color="red"
                   :content="item.badge"></v-badge>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
          <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
        </v-list-item-content>
      </v-list-item>


    </v-list-group>


    <v-list-item link :href="getDocsUrl()" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-file-code</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Документация</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>


    <v-list-item link @click="logout">
      <v-list-item-action>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Выйти</v-list-item-title>

      </v-list-item-content>
    </v-list-item>


  </v-list>

</template>

<script>
export default {
  name: 'AdminMenu',

  data: () => ({

    myImage: require('@/logo.svg'),

    sidePanel: [

      {
        text: "Основное меню", icon: 'mdi-cash-sync', items: [
          {text: 'Открытые транзакции', icon: 'mdi-cash-sync', route: '/transactions'},
          {text: 'Архив транзакций', icon: 'mdi-archive', route: '/transactions_archive'},
          {text: 'Выводы', icon: 'mdi-hand-coin', route: '/withdraws'},
          {text: 'Банковские карты', icon: 'mdi-credit-card-multiple', route: '/cards'},
          {text: 'Баланс карт', icon: 'mdi-credit-card-search', route: '/cardsbalances'},

          {text: 'Банки', icon: 'mdi-bank', route: '/banks'},
          {text: 'Юзеры', icon: 'mdi-account-supervisor', route: '/users'},
          {text: 'Выплаты', icon: 'mdi-cash-minus', route: '/payouts'},
          {text: 'История коррекций', icon: 'mdi-history', route: '/history/0'},


        ]
      },





    ],
  }),

  computed: {
    role() {
      return this.$store.state.user.role
    },


  },


  methods: {

    getDocsUrl(){
      return process.env.VUE_APP_URL_DOCS;
    },



    getBrand(){
      return process.env.VUE_APP_BRAND;
    },

    logout() {
      this.$store.dispatch('auth/onLogout');
    }
  }

}
</script>
