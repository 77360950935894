<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3"
             text v-on="on">
        {{ mode === "new" ? "создание" : "правка" }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>Выплата - {{ mode === "new" ? "создание" : "правка" }}</v-card-title>

      <div>

        <v-form>
          <v-container>
            <v-row>
              <v-col cols="10">

                <v-text-field v-model="item.amount" label="Сумма" type="number" outlined clearable></v-text-field>
                <!--v-text-field v-model="item.bankname" label="Банк" outlined clearable></v-text-field-->

                <BankCombobox2 v-on:changed="cbank"></BankCombobox2>

                <v-text-field v-if="sbp" v-model="item.bankname" label="Название банка" outlined required
                              clearable></v-text-field>

                <v-text-field v-if="sbp"   maxlength=11 v-model="item.number" label="Номер телефона" required   outlined clearable></v-text-field>
                <v-text-field v-else v-model="item.number" label="Номер карты" required   outlined clearable></v-text-field>

                <v-text-field v-model="item.owner" label="Получатель" outlined clearable></v-text-field>

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


import BankCombobox2 from "../controls/BankCombobox2.vue";

export default {

  name: 'PayoutsEditDialog',
  components: {BankCombobox2},

  data: function () {
    return {
      dialog: false,
      sbp: false

    }
  },

  props: ['item', 'mode'],


  methods: {

    cbank(val) {

      this.item.bankname = null

      this.sbp = val.name === "СБП"

      if (this.sbp) {
        this.item.sbp = true
        this.item.bankname = ""
      } else {
        this.item.sbp = false
        this.item.bankname = val.name
      }
    },


    ok: function () {


      if(!this.item.bankname){
        alert("Банк не выбран")
        return
      }

      this.dialog = false;

      if (this.sbp &&  !this.item.bankname.startsWith("СБП"))
        this.item.bankname =    "СБП " + this.item.bankname

      this.$emit('ok', this.item, this.mode)
    }
  },


}

</script>
