var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _c("DatePeriodPicker", {
        attrs: { caption: "Период выборки", from: _vm.from, to: _vm.to },
        on: { changed: _vm.onPerodChanged },
      }),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap pa-0 mt-2", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "130", width: "300" },
            },
            [
              _c("span", [
                _c("strong", [_vm._v("Поиск по ID")]),
                _vm._v(" - только полное соответствие. "),
              ]),
              _c("v-text-field", {
                attrs: {
                  label: "ID",
                  outlined: "",
                  clearable: "",
                  type: "number",
                  max: "2147483647",
                  oninput: "validity.valid||(value='');",
                },
                model: {
                  value: _vm.id,
                  callback: function ($$v) {
                    _vm.id = $$v
                  },
                  expression: "id",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "130", width: "300" },
            },
            [
              _vm._v(" Итого суммы по текущей странице:"),
              _c("br"),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.sum.toLocaleString("ru-RU")) +
                    " в валюте терминала "
                ),
              ]),
              _c("br"),
              _vm._v(_vm._s(_vm.sumUsdt.toLocaleString("ru-RU")) + " USDT "),
              _c("br"),
              _c("span", { staticClass: "font-weight-thin" }, [
                _vm._v("Суммируется только completed по текущей странице."),
              ]),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "130", width: "300" },
            },
            [
              _c("span", [_vm._v(" Скачать CSV по выборке в кодировке:")]),
              _c("br"),
              _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  on: {
                    click: function ($event) {
                      return _vm.csvRequest(false)
                    },
                  },
                },
                [_vm._v("UTF8")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  on: {
                    click: function ($event) {
                      return _vm.csvRequest(true)
                    },
                  },
                },
                [_vm._v("Win1251")]
              ),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "130", width: "300" },
            },
            [
              _c("span", [_vm._v(" Отфильтровать:")]),
              _c("br"),
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", dense: "" },
                  model: {
                    value: _vm.radios,
                    callback: function ($$v) {
                      _vm.radios = $$v
                    },
                    expression: "radios",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Только успешные", value: "1" },
                  }),
                  _c("v-radio", { attrs: { label: "Все", value: "2" } }),
                  _c("v-radio", {
                    attrs: { label: "Только отмены", value: "3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "mt-1",
            attrs: {
              "hide-default-footer": "",
              "disable-pagination": "",
              dense: "",
              items: _vm.transactions,
              headers: _vm.computedHeaders,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [
                  50, 100, 200, 100000, 500000, 1000000, -1,
                ],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_c("StatusCell", { attrs: { item: item } })]
                },
              },
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(Date.parse(item.updatedAt)).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.amount",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.amount.toLocaleString("ru-RU", {
                            style: "currency",
                            currency: _vm.terminal,
                          })
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.currency",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.bank ? _vm.get$ByCountry2(item) : "")
                    ),
                  ]
                },
              },
              {
                key: "item.offer",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.offer.amount.toLocaleString("ru-RU", {
                            style: "currency",
                            currency: "RUB",
                          })
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.usdt",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.usdt(item).toLocaleString("ru-RU"))
                    ),
                  ]
                },
              },
              {
                key: "item.bank",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.bank ? item.bank.name : "--")),
                  ]
                },
              },
              {
                key: "item.bankRate",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.offer ? item.offer.rate : "--")),
                  ]
                },
              },
              {
                key: "item.uid",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.uid))]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "text-center pt-2" },
            [
              _c("v-pagination", {
                attrs: { length: _vm.pageCount, "total-visible": 15 },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center ma-2" },
                [
                  _c("span", { staticClass: "mr-1 mt-1" }, [
                    _vm._v(" Показывать на странице: "),
                  ]),
                  _c("v-select", {
                    staticStyle: { "max-width": "120px" },
                    attrs: { dense: "", items: _vm.Pages, "return-object": "" },
                    model: {
                      value: _vm.itemsPerPage,
                      callback: function ($$v) {
                        _vm.itemsPerPage = $$v
                      },
                      expression: "itemsPerPage",
                    },
                  }),
                  _c("span", { staticClass: "ml-1 mt-1" }, [
                    _vm._v(" строк из " + _vm._s(_vm.totalRows)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }