var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pa-2" }, [
    _c("div", { staticClass: "Transactions" }, [
      _c("h3", [
        _vm._v(
          "Транзакции - " +
            _vm._s(_vm.getBrand()) +
            " payments " +
            _vm._s(_vm.terminal) +
            " "
        ),
      ]),
    ]),
    _c("div", [_c("TransactionsUsd")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }