<template>

  <v-text-field
      v-model="text2"
      label="ИД чата"
      outlined
      clearable
      type="number"
      min="0"

      @input = "input"

      oninput="validity.valid||(value='');"

  ></v-text-field>


</template>

<script>

export default {
  name: "ChatIDInput",

  props: ['text'],


  data: function () {
    return {
      text2: null,

    }
  },


  mounted() {
    this.text2 = this.text
  },

  methods: {

    input(val) {
      this.$emit('update:text', val)
    }

  },

}
</script>

