var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      label: "Страна-валюта",
      items: _vm.Countries,
      "item-text": (item) => item.country,
      "return-object": "",
      outlined: "",
      clearable: "",
    },
    model: {
      value: _vm.val2,
      callback: function ($$v) {
        _vm.val2 = $$v
      },
      expression: "val2",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }