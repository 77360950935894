var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            readonly: "",
                            label: _vm.label,
                            "prepend-icon": "mdi-calendar",
                            rules: [_vm.rules.required, _vm.rules.date],
                          },
                          model: {
                            value: _vm.dateLoc,
                            callback: function ($$v) {
                              _vm.dateLoc = $$v
                            },
                            expression: "dateLoc",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              elevation: "15",
              "show-adjacent-months": "",
              "header-color": "primary",
              locale: "ru-ru",
              "first-day-of-week": 1,
              max: _vm.max,
              min: _vm.min,
            },
            on: { change: _vm.save },
            model: {
              value: _vm.isoDate,
              callback: function ($$v) {
                _vm.isoDate = $$v
              },
              expression: "isoDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }