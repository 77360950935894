var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-10",
            attrs: {
              dense: "",
              items: _vm.withdrawals,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_c("StatusCell", { attrs: { item: item } })]
                },
              },
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.updatedAt } }),
                  ]
                },
              },
              {
                key: "item.amount",
                fn: function ({ item }) {
                  return [_c("FinanceCell", { attrs: { value: item.amount } })]
                },
              },
              {
                key: "item.payoutAddressCurrency",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      _vm._s(
                        item.withdrawalAddress
                          ? item.withdrawalAddress.currency
                          : "--"
                      ) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.payoutAddressAddress",
                fn: function ({ item }) {
                  return [
                    _c("WalletView", {
                      attrs: {
                        wallet: item.withdrawalAddress
                          ? item.withdrawalAddress.address
                          : "--",
                      },
                    }),
                  ]
                },
              },
              {
                key: "item.payoutAddressNetwork",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      _vm._s(
                        item.withdrawalAddress
                          ? item.withdrawalAddress.network
                          : "--"
                      ) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.userUsername",
                fn: function ({ item }) {
                  return [
                    _vm._v(_vm._s(item.user ? item.user.username : "--") + " "),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    item.status === "Pending"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "blue",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.accept(item.id)
                              },
                            },
                          },
                          [_vm._v("Принять в работу ")]
                        )
                      : _vm._e(),
                    item.status === "Processing"
                      ? _c("WithdrawFinishDialog", {
                          attrs: { caption: "Выполнено", tag: item.id },
                          on: { ok: _vm.finish },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions mt-10" }, [
      _c("h3", [_vm._v("Выводы - Админ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }