<template>


    <v-dialog
            v-model="dialog"
            width="700"
    >
        <template v-slot:activator="{ on }">
            <v-btn :class="buttonclass" text v-on="on">{{ buttontext }}</v-btn>
        </template>

        <v-card>
            <v-card-title primary-title>{{caption}}</v-card-title>
            <v-card-text> Необходимо ввести текст:</v-card-text>
            <v-card-actions>

                <v-textarea
                        name="input-7-1"
                        label="ввести тут"
                        v-model="value"
                        :hint="hint"
                        required
                ></v-textarea>

            </v-card-actions>

            <v-divider></v-divider>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                >
                    Отмена
                </v-btn>

                <v-btn

                        color="primary"
                        text
                        @click="ok"
                >
                    Ок
                </v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>

</template>


<script>
    module.exports =
        {

          name: 'TextInputDialog',

            data: function () {
                return {
                    hint: "Подсказка: нужно ввести",
                    dialog: false,
                    value: ""
                }
            },

            props: ['tag', 'caption', 'buttontext','buttonclass'],

            methods: {
                ok: function () {
                    if (!this.value.trim()) {
                        alert('Вначале нужно ввести!')
                        return;
                    }

                   // console.log("dialog ok");
                   // console.log(this.value);

                    this.dialog = false;
                    this.$emit('ok', this.tag, this.value)
                }
            },

            mounted() {

            },
        }
</script>
