var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: {
      label: "ИД чата",
      outlined: "",
      clearable: "",
      type: "number",
      min: "0",
      oninput: "validity.valid||(value='');",
    },
    on: { input: _vm.input },
    model: {
      value: _vm.text2,
      callback: function ($$v) {
        _vm.text2 = $$v
      },
      expression: "text2",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }