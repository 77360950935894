import axios from 'axios';

// Создаем экземпляр Axios для запросов авторизации (Login)
const loginInstance = axios.create({
    baseURL: process.env.VUE_APP_REST_API_URL_AUTH,
    headers: {
        "Content-Type": 'application/json'
    }
});

const defaultConfig =
{
    baseURL: 'https://api.example.com',
    headers : {
        "Content-Type" :  'application/json'
    }
}

const token = localStorage.getItem('accessToken');
if (token)
    defaultConfig.headers['authorisation'] =`Bearer ${token}`

// Создаем экземпляр Axios для обычных запросов (Default)
const defaultInstance = axios.create(defaultConfig);

// Экспортируем экземпляры Axios для использования в других файлах
export {loginInstance, defaultInstance};
