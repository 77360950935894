var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.role === "superadmin" ||
      _vm.role === "administrator" ||
      _vm.role === "operator"
        ? _c("History", { attrs: { cardId: _vm.cardId } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }