<template>

  <v-select

      @change="change"

      v-model="id2"
      label="Банк"
      :items="banks"

      item-value="id"

      :item-text="item => item.name "
      return-object
      outlined
      clearable
  ></v-select>


</template>

<script>

import gql from "graphql-tag";

//<BankCombobox v-bind:id.sync="item.bankId"></BankCombobox>

export default {
  name: "BankCombobox",

  props: ['id'],


  data: function () {
    return {
      id2: null,
      // dialog: false,
      banks: [],
      //id: null,
      //init: false
      selectedItem: null
    }
  },

  apollo: {
    $subscribe: {
      banks: {
        query: gql`
  subscription {
  banks(order_by: {id: desc}) {
    status
    rate
    name
    logo
    id
  }
}
`,
        result({data}) {
          this.banks = data.banks;
        },
      },
    },
  },

  mounted() {
    this.id2 = this.id


    if (!this.id)
      console.log("ERROR - bankId must be defined");
  },

  methods: {


    change(val) {
      this.$emit('update:id', val.id)
    }

  },




}
</script>

