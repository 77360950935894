
  <template v-slot:item.status="{ item }">
    <v-chip v-if="item.status==='created'" color="primary"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='canceled'" color="gray"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='offer'" color="orange"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='accepted'" color="green"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='proof'" color="blue"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='completed'" color="green"> {{ item.status }}</v-chip>

    <v-chip v-else-if="item.status==='Pending'" color="gray"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='Processing'" color="orange"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='Success'" color="green"> {{ item.status }}</v-chip>
    <v-chip v-else-if="item.status==='Canceled'" color="red"> {{ item.status }}</v-chip>

    <v-chip v-else color="brown"> {{ item.status }}</v-chip>
  </template>


<script>
export default {
  name: "StatusCell",

  props: [ 'item'],
//"Pending", "Processing", "Success", "Canceled"
}
</script>

<style scoped>

</style>