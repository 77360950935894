import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {apolloProvider} from './store/appoloProvider'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_TESTING




let app = new Vue({
    router,
    store,
    vuetify,
    apolloProvider,

    mounted() {
        //const stopCountdown =
       //     setInterval(() => {
         //   this.$store.dispatch('authentication/refreshToken');
       // }, 1000 * 60 * 10 )
    },

    render: h => h(App)
})

app.$mount('#app')

window.app = app
