<template>

  <v-list dense>

    <v-list-item class="mb-10">
      <v-list-item-action>
        <v-img
            max-height="220"
            max-width="132"
            :src="myImage"
        ></v-img>

      </v-list-item-action>
      <v-list-item-content>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-action>

      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Роль: {{ role }}</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>


    <v-list-group
        v-for="item0 in sidePanel"
        :key="item0.text"
        no-action
        :prepend-icon="item0.icon"
    >

      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title v-text="item0.text"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item link v-for="(item, index) in item0.items" :key="index" :to="item.route">

        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
          <v-badge v-if="item.badge"
                   color="red"
                   :content="item.badge"></v-badge>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
          <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
        </v-list-item-content>
      </v-list-item>


    </v-list-group>


    <v-list-item link :href="getBrand()" target="_blank">
      <v-list-item-action>
        <v-icon>mdi-file-code</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Документация</v-list-item-title>
        <!--v-list-item-subtitle v-text="index"></v-list-item-subtitle-->
      </v-list-item-content>
    </v-list-item>

    <v-list-item link  @click="logout">
      <v-list-item-action>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Выйти</v-list-item-title>

      </v-list-item-content>
    </v-list-item>


  </v-list>

</template>

<script>
export default {
  name: 'ExchangeOperatorMenu',

  data: () => ({

    myImage: require('@/logo.svg'),

    sidePanel: [




      {
        text: "Меню обменника", icon: 'mdi-swap-horizontal', items: [
          {text: 'Юзеры', icon: 'mdi-swap-horizontal', route: '/exchangeUsers'},
          {text: 'Верификация', icon: 'mdi-swap-horizontal', route: '/exchangeUsersVerify'},

          {text: 'Продажа ', icon: 'mdi-swap-horizontal', route: '/exchangePayoutsUser'},

          {text: 'Конвертации ', icon: 'mdi-swap-horizontal', route: '/exchangeHistory'},

          {text: 'Архив покупок', icon: 'mdi-swap-horizontal', route: '/ExchangeTransactionsArchiveFull'},

          {text: 'Выводы', icon: 'mdi-swap-horizontal', route: '/exchangeConclusion'},
          {text: 'Выводы. Архив', icon: 'mdi-swap-horizontal', route: '/exchangeConclusionHistory'},

          {text: 'Депозиты', icon: 'mdi-swap-horizontal', route: '/ExchangeDeposits'},
          {text: 'Депозиты. Архив', icon: 'mdi-swap-horizontal', route: '/ExchangeDepositsHistory'},
          {text: 'Дешборд', icon: 'mdi-swap-horizontal', route: '/ExchangeConfig'},

          {text: 'История операций', icon: 'mdi-cash-minus', route: '/historyAll'},
            // {text: 'Депозиты - Счета', icon: 'mdi-cash-minus', route: '/exchangeDepositInvoice'},

        ]
      },

      {
        text: "Наличные", icon: 'mdi-swap-horizontal', items: [
          {text: 'Купить за наличные', icon: 'mdi-swap-horizontal', route: '/cash'},
          {text: 'Продать за наличные', icon: 'mdi-swap-horizontal', route: '/cashSell'},
          {text: 'Архив наличные', icon: 'mdi-swap-horizontal', route: '/cashArchive'},


        ]
      },

    ],
  }),

  computed: {
    role() {
      return this.$store.state.user.role
    },
  },


  methods: {

    getBrand(){
      return process.env.VUE_APP_BRAND;
    },

    logout() {
      this.$store.dispatch('auth/onLogout');
    }
  }


}
</script>
