var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "indigo ma-3", attrs: { text: "" } }, on),
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.mode === "new" ? "создание" : "правка") +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(
              "Выплата - " + _vm._s(_vm.mode === "new" ? "создание" : "правка")
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Сумма",
                                  type: "number",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "amount", $$v)
                                  },
                                  expression: "item.amount",
                                },
                              }),
                              _c("BankCombobox2", {
                                on: { changed: _vm.cbank },
                              }),
                              _vm.sbp
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "Название банка",
                                      outlined: "",
                                      required: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.item.bankname,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "bankname", $$v)
                                      },
                                      expression: "item.bankname",
                                    },
                                  })
                                : _vm._e(),
                              _vm.sbp
                                ? _c("v-text-field", {
                                    attrs: {
                                      maxlength: "11",
                                      label: "Номер телефона",
                                      required: "",
                                      outlined: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.item.number,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "number", $$v)
                                      },
                                      expression: "item.number",
                                    },
                                  })
                                : _c("v-text-field", {
                                    attrs: {
                                      label: "Номер карты",
                                      required: "",
                                      outlined: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.item.number,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "number", $$v)
                                      },
                                      expression: "item.number",
                                    },
                                  }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Получатель",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.item.owner,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "owner", $$v)
                                  },
                                  expression: "item.owner",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Отмена ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.ok },
                },
                [_vm._v(" Ок ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }