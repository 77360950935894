var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", [_vm._v("Кошельки")]),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap", attrs: { fluid: "" } },
        _vm._l(_vm.withdrawalAddresses, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "ma-1" },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  attrs: { "max-width": "344", outlined: "" },
                },
                [
                  _c("v-card-title", [
                    _vm._v("Валюта: " + _vm._s(item.currency) + " "),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v("Сеть: " + _vm._s(item.network)),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm._v("Адрес: "),
                      _c("WalletView", { attrs: { wallet: item.address } }),
                      _c("br"),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("WithdrawEditDialog", {
                        attrs: { wallet: item },
                        on: { ok: _vm.withdrawnew },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }