<template>

  <v-list dense>

    <v-list-item class="mb-10">
      <v-list-item-action>
        <v-img
            max-height="220"
            max-width="132"
            :src="myImage"
        ></v-img>

      </v-list-item-action>
      <v-list-item-content>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-action>

      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ role }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link v-for="(item, index) in sidePanel" :key="index" :to="item.route">
      <v-list-item-action>
        <v-icon>{{ item.icon }}</v-icon>
        <v-badge v-if="item.badge"
                 color="red"
                 :content="item.badge"></v-badge>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title v-text="item.text"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>


    <v-list-item link  @click="logout">
      <v-list-item-action>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Выйти</v-list-item-title>

      </v-list-item-content>
    </v-list-item>

  </v-list>

</template>

<script>
export default {
  name: 'SuperOperatorMenu',

  data: () => ({
    myImage: require('@/logo.svg'),
    role: "Роль: SuperOperator",
    sidePanel: [
      {text: 'Транзакции', icon: 'mdi-cash-sync', route: '/transactions'},
      {text: 'Архив транзакций', icon: 'mdi-archive', route: '/transactions_archive'},
     // {text: 'Банковские карты', icon: 'mdi-credit-card-multiple', route: '/cards'},
      {text: 'Выплаты', icon: 'mdi-cash-minus', route: '/payouts'},
      {text: 'Банки', icon: 'mdi-bank', route: '/banks'},

      {text: 'Баланс карт', icon: 'mdi-credit-card-search', route: '/cardsbalances'},
      {text: 'История коррекций', icon: 'mdi-history', route: '/history'},
    ],
  }),


  methods: {

    logout() {
      this.$store.dispatch('auth/onLogout');
    }
  }
}
</script>
