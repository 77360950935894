<template>

  <div>
    <WithdrawsAdmin v-if="role==='superadmin'"></WithdrawsAdmin>
    <WithdrawsUser v-if="role==='merchant'"></WithdrawsUser>
  </div>

</template>

<script>

import WithdrawsUser from "../components/WithdrawsUser";
import WithdrawsAdmin from "../components/WithdrawsAdmin";


export default {
  name: 'Withdraws',
  components: {WithdrawsAdmin, WithdrawsUser},

  data: () => ({}),

  computed: {
    role() {
      return this.$store.state.user.role
    },
  },

  methods: {},

}
</script>
