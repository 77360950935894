<template>
  <div>

    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >

      <template v-slot:activator="{ on, attrs }">

        <v-text-field
            readonly
            v-model="dateLoc"
            :label=label
            prepend-icon="mdi-calendar"
            :rules="[rules.required, rules.date]"
            v-bind="attrs"

            v-on="on"
        ></v-text-field>

      </template>

      <v-date-picker
          elevation="15"
          show-adjacent-months
          header-color="primary"
          v-model="isoDate"
          locale="ru-ru"
          :first-day-of-week="1"
          :max="max"
          :min="min"
          @change="save"
      ></v-date-picker>

    </v-menu>
  </div>
</template>

<script>
export default {

  data: () => ({
    isoDate: null,
    dateLoc: null,
    activePicker: null,
    menu: false,

    rules: {
      required: value => !!value || 'Обязатальное поле.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      date: value => {
        const pattern = /^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.]\d{4}$/
        return pattern.test(value) || 'Неверный формат даты.'
      },

      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
  }),

  props: ['label', 'min', 'date', 'max'],
//js Date object

  watch: {

   /* dateLoc(s) {
      let a = s.split('.')
      return  this.getISODate (new Date(a[2],a[1],a[0]) )
    },*/

    date(val) {   //костыль  если свойство изменится  в компоненте   период пикер
      this.isoDate = val
    },

    isoDate(val) {
      this.dateLoc = this.locdate(val)
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  mounted() {
    this.isoDate = this.date
    this.dateLoc = this.locdate(this.date)
  },

  methods: {

    getISODate(date) {
      return (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
    },

    locdate(isodate){
      return new Date(Date.parse(isodate)).toLocaleDateString()
    },


    save(date) {
      this.$refs.menu.save(date)
      this.dateLoc = this.locdate()
      this.$emit('save', date)
    },
  },
}
</script>