var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-2" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap pa-0 mt-2", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "70", width: "300" },
            },
            [_c("DirectRefillDialog", { on: { ok: _vm.directRefill } })],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "70", width: "300" },
            },
            [
              _c("ChatIDInput", {
                attrs: { text: _vm.chatid },
                on: {
                  "update:text": function ($event) {
                    _vm.chatid = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-3",
            attrs: {
              dense: "",
              items: _vm.transactions,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_c("StatusCell", { attrs: { item: item } })]
                },
              },
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _c("DateTimeCell", { attrs: { value: item.updatedAt } }),
                  ]
                },
              },
              {
                key: "item.amount",
                fn: function ({ item }) {
                  return [
                    _c("FinanceCell", { attrs: { value: item.offer.amount } }),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.get$ByCountry2(item))),
                    ]),
                  ]
                },
              },
              {
                key: "item.cardNumber",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.card ? item.card.number : "--")),
                  ]
                },
              },
              {
                key: "item.cardOwner",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.card ? item.card.owner : "--")),
                  ]
                },
              },
              {
                key: "item.userName",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.user ? item.user.username : "--")),
                  ]
                },
              },
              {
                key: "item.bank",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.bank ? item.bank.name : "--")),
                  ]
                },
              },
              {
                key: "item.fee",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.offer.fee))]
                },
              },
              {
                key: "item.rate",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.offer.rate))]
                },
              },
              {
                key: "item.currency",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.bank
                            ? _vm.get$TickerByCountry(item.bank.country)
                            : ""
                        )
                    ),
                  ]
                },
              },
              {
                key: "item.chatId",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.card
                            ? item.card.chatId !== null
                              ? item.card.chatId
                              : "null"
                            : "--"
                        )
                    ),
                  ]
                },
              },
              {
                key: "item.info",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.info
                            ? item.info.card
                              ? item.info.card
                              : "-"
                            : "--"
                        )
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    item.status === "accepted" ||
                    item.status === "proof" ||
                    item.status === "offer"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "green mr-1 mb-1",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.approve(item)
                              },
                            },
                          },
                          [_vm._v("Одоб ")]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "red mr-1 mb-1",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.del(item)
                          },
                        },
                      },
                      [_vm._v("Удал")]
                    ),
                    item.status === "created"
                      ? _c("CardSelectDialog", {
                          attrs: { caption: "Выд.карту", tag: item.id },
                          on: { ok: _vm.card },
                        })
                      : _vm._e(),
                    item.status === "accepted" ||
                    item.status === "proof" ||
                    item.status === "offer"
                      ? _c("NumberEditDialog", {
                          attrs: {
                            caption: "Правк",
                            title: "Правка суммы",
                            label: "Сумма ",
                            tag: item.id,
                            value: item.offer.amount,
                          },
                          on: { ok: _vm.amount },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions ma-2" }, [
      _c("h3", [_vm._v("Открытые транзакции - operator")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }