<template>

  <v-select
      @change="change"

      v-model="bank2"
      label="Банк"
      :items="banks"

      :item-text="item => item.name "
      return-object
      outlined
      clearable
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "BankCombobox3",

  props: [ 'bank'],

  data: function () {
    return {
      bank2:null,
      banks: [],
    }
  },

  apollo: {
    $subscribe: {
      banks: {
        query: gql`
  subscription {
  banks(order_by: {id: desc}) {
    status
    rate
    name
    logo
    id
  }
}
`,
        result({data}) {
          this.banks = data.banks;
        },
      },
    },
  },

  mounted() {
    this.bank2 = this.bank
  },

  methods: {
    change(val) {
      this.$emit('update:bank', val)
    }
  },
}
</script>