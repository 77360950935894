// myModule.js

import {AuthAPI} from "../login";
import {defaultInstance} from "../login/myaxios";
import {parseJwt} from "./parseJwt";


export const auth = {

    namespaced: true,

    state: {
        credentials: {
            accessToken: localStorage.getItem('accessToken') || null,
            refreshToken: localStorage.getItem('refreshToken') || null,
        },

        // Состояние модуля
        data: null,
        isLoading: false,
        error: null,
    },
    mutations: {

        setAccessToken(state, token) {
            state.credentials.accessToken = token
            localStorage.setItem('accessToken', token)
        },

        deleteAccessToken(state) {
            state.credentials.accessToken = null
            localStorage.removeItem('accessToken')
        },


        setRefreshToken(state, token) {
            state.credentials.refreshToken = token
            localStorage.setItem('refreshToken', token)
        },

        deleteRefreshToken(state) {
            state.credentials.refreshToken = null
            localStorage.removeItem('refreshToken')
        },

    },
    actions: {

        onLogout({dispatch, commit}) {
            commit('deleteAccessToken')
            commit('deleteRefreshToken')
            delete defaultInstance.defaults.headers['authorisation']
            dispatch('user/onUserClearRequest', '', {root: true})
            location.reload();
        },

        onLogin({dispatch, commit}, {username, password}) {
            return AuthAPI.login(username, password).then(
                async (res) => {
                    commit('setAccessToken', res.data.accessToken)
                    commit('setRefreshToken', res.data.refreshToken)

                    defaultInstance.defaults.headers['authorisation'] = `Barier ${res.data.accessToken}`

                    let pt = parseJwt(res.data.accessToken);
                    await dispatch('user/onUserFetchRequest', pt.id, {root: true});//  must wait for
                }
            )
        },

        onRefreshToken({dispatch,commit}, refreshToken) {
            return AuthAPI.refreshToken(refreshToken).then(
                async (res) => {
                    commit('setAccessToken', res.data.accessToken)
                    commit('setRefreshToken', res.data.refreshToken)
                    defaultInstance.defaults.headers['authorisation'] = `Barier ${res.data.accessToken}`
                }).catch((result)=>{
                    console.error(result)//TODO: refresh token fetch error - do logout without understanding error
                    dispatch('onLogout')//'', {root: true}
            })
        },



        // Действия для асинхронных операций
        fetchData({commit}) {
            commit('SET_LOADING', true);

            // Выполнение асинхронного запроса или операции
            // Пример использования Axios:
            /*  axios.get('/api/data')
                  .then(response => {
                      commit('SET_DATA', response.data);
                      commit('SET_LOADING', false);
                  })
                  .catch(error => {
                      commit('SET_ERROR', error);
                      commit('SET_LOADING', false);
                  }); */
        },
    },

    getters: {
        // Геттеры для получения данных из состояния
        getData: state => state.data,
        isLoading: state => state.isLoading,
        error: state => state.error,
    },
};

export default auth;
