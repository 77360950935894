<template>
  <div>
    <PayoutsAdmin v-if="role==='superadmin' || role==='administrator'  "></PayoutsAdmin>
    <PayoutsUser v-if="role==='merchant'"></PayoutsUser>
  </div>

</template>

<script>

import PayoutsUser from "../components/PayoutsUser";
import PayoutsAdmin from "../components/PayoutsAdmin";

export default {
  name: 'Payouts',

  components: {
    PayoutsAdmin, PayoutsUser
  },

  data: () => ({}),

  computed: {

    role() {
      return this.$store.state.user.role
    },

  },

  methods: {},

}
</script>
