var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._v(" " + _vm._s(_vm.caption) + " "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("DatePicker2", {
                attrs: {
                  label: "От даты",
                  min: "2021-01-01",
                  date: _vm.from2,
                  max: _vm.to2,
                },
                on: { save: _vm.onFrom },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("DatePicker2", {
                attrs: {
                  label: "До даты",
                  min: _vm.from2,
                  date: _vm.to2,
                  max: "2025-12-31",
                },
                on: { save: _vm.onTo },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-btn", { on: { click: _vm.year } }, [_vm._v("Год")]),
      _c("v-btn", { on: { click: _vm.quarter } }, [_vm._v("Этот квартал")]),
      _c("v-btn", { on: { click: _vm.month } }, [_vm._v("Этот месяц")]),
      _c("v-btn", { on: { click: _vm.week } }, [_vm._v("Эта неделя")]),
      _c("v-btn", { on: { click: _vm.yesterday } }, [_vm._v("Вчера")]),
      _c("v-btn", { on: { click: _vm.today } }, [_vm._v("Сегодня")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }