var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "center", justify: "center" } },
            [
              _c("v-img", {
                staticClass: "mb-10",
                attrs: {
                  "max-height": "220",
                  "max-width": "132",
                  src: _vm.myImage,
                },
              }),
              _c("h3", [_vm._v("Вход в аккаунт")]),
              _c("LoginPage"),
              _c("div", [
                _c("span", [
                  _vm._v(
                    "Нет аккаунта? Напишите нам - " + _vm._s(_vm.getEmail())
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mt-10" },
                [
                  _c(
                    "v-btn",
                    { attrs: { href: _vm.getSupport(), target: "_blank" } },
                    [
                      _vm._v(" Поддержка 24/7 "),
                      _c("v-icon", [_vm._v("mdi-send-circle")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }