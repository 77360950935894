<template>

  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-5">
      <h3>Архив - транзакции*</h3>
    </div>


    <div class="mt-5">
      <p><strong>Фильтр по столбцам</strong> - работает по вхождению подстроки. В ID - только цифры.</p>
      <v-form>

        <v-row>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <v-text-field
                v-model="id"
                label="ID"
                outlined
                clearable
                type="number"
                max="2147483647"
                oninput="validity.valid||(value='');"

            ></v-text-field>

          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >

            <v-text-field
                v-model="card"
                label="Карта"
                outlined
                clearable

            ></v-text-field>

          </v-col>


          <v-col
              cols="12"
              sm="6"
              md="2"
          >
            <v-text-field
                v-model="bank"
                label="Банк"
                outlined
                clearable

            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >
            <v-text-field
                v-model="user"
                label="Мерчант"
                outlined
                clearable
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >


            <v-radio-group row dense v-model="radios">
              <v-radio label="Только успешные" value="1"></v-radio>
              <v-radio label="Все" value="2"></v-radio>
              <v-radio label="Только отмены" value="3"></v-radio>
            </v-radio-group>
          </v-col>


        </v-row>


      </v-form>
    </div>


    <div>
      <v-data-table
          sort-by="id"
          sort-desc
          dense
          multi-sort
          :items="transactions"
          :headers="headers"
          class="elevation-1 mt-3"
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]  }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">
          <v-chip v-if="item.status==='created'" color="primary"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='canceled'" color="gray"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='completed'" color="green"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='offer'" color="orange"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='accepted'" color="green"> {{ item.status }}</v-chip>
          <v-chip v-else-if="item.status==='proof'" color="blue"> {{ item.status }}</v-chip>
          <v-chip v-else color="brown"> {{ item.status }}</v-chip>
        </template>
        <template v-slot:item.createdAt="{ item }"> {{
            new Date(Date.parse(item.updatedAt)).toLocaleString()
          }}
        </template>

        <template v-slot:item.amountUsd="{ item }"> {{ fin(calcUsdAmount(item)) }} $</template>

        <template v-slot:item.amountRub="{ item }"> {{ fin(calcRubAmount(item)) }}  </template>

        <template v-slot:item.currency="{ item }"> {{item.bank ?  get$ByCountry(item.bank.country):""   }} </template>

        <template v-slot:item.fee="{ item }"> {{ item.offer.fee }}</template>
        <template v-slot:item.rate="{ item }"> {{ item.offer.rate }}</template>

        <template v-slot:item.usdt="{ item }"> {{ fin(usdt(item)) }}</template>



        <template v-slot:item.bank="{ item }"> {{ item.bank ? item.bank.name : '--' }}</template>

        <template v-slot:item.card="{ item }"> {{ item.card ? item.card.number : '--' }}</template>
        <template v-slot:item.cardowner="{ item }"> {{ item.card ? item.card.owner : '--' }}</template>

        <template v-slot:item.user="{ item }"> {{ item.user ? item.user.username : '--' }}</template>

        <template v-slot:item.actions="{ item }">

          <v-btn class="orange" v-if="item.status==='canceled'  " dense text
                 @click="patch(item)">в Completed
          </v-btn>


          <v-btn class="primary" dense text
                 @click="repeat(item)">Повтор колбэк
          </v-btn>

        </template>

      </v-data-table>
    </div>
  </div>

</template>

<script>


import gql from "graphql-tag";
import {getHeaders} from "../store";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/transaction"

import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";

export default {
  name: 'TransactionsArchiveDay',

  components: { Snackbar},

  apollo: {
    // $subscribe:{
    transactions: {
      query: gql`query  s($from: timestamptz! = "2021-01-01", $to: timestamptz! = "2025-01-01",   $exp:  transactions_bool_exp!  ) {
  transactions(where: {

    _and:[

        {updatedAt: {_gte: $from}},
        {updatedAt: {_lte: $to}},



        $exp
        ]
      } ,

    order_by: {id: desc} ) {
    id
    updatedAt
    uid
    merchantId
    status
    offer
    createdAt
    currency
    amount
    card {
      owner
      number
    }
    bank {
      name
      country
    }
    user {
      username
    }
  }
}
`,
      // pollInterval: 300,

      variables() {

        let id_exp = this.id ? {id: {_eq: this.id}} : {};

        let status_exp = {}
        switch (this.radios) {
          case "1":
            status_exp = {status: {_eq: "completed"}}
            break
          case "3":
            status_exp = {status: {_eq: "canceled"}}
            break

          default:
            status_exp = {}
        }

        let bank_exp = this.bank ?  {bank: {name: {_ilike: `%${this.bank}%`}}} : {}
        let user_exp = this.user ?  {user: {username: {_ilike: `%${this.user}%`}}} : {}
        let card_exp =  this.card ? {card: {number: {_ilike: `%${this.card}%`}}} : {}

        let country_exp = this.country ? {bank: {country: {_eq: `${this.getCountry()}`}}}: {}

        let exp = {_and: [id_exp, status_exp, bank_exp,user_exp,card_exp,country_exp]}

        return {
          from: this.from,
          to: this.to,
          exp: exp,
         // card: this.card,
         // bank: this.bank,
          //user: this.user,
          // status: this.radios === "1" ? ["completed"] : this.radios === "2" ? ["created", "completed", "canceled"] : ["canceled"],
          //status: this.radios === "1" ? ["completed"] : this.radios === "2" ? ["created","completed", "canceled"] : ["canceled"],

        }
      },

      result({data}) {
        this.transactions = data.transactions;
      },

      //  },
    }
  },

  data: () => ({

    showCanceled: false,
    radios: "1",

    search: "",
    id: "",
    card: "",
    bank: "",
    user: "",

    filter: {},

    from: "2024-01-01",
    to: "2024-12-31",

    filterByDatePeriod: false,
    transactions: [],

    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Дата обн.', value: 'createdAt'},

      {text: 'Сумма USD', value: 'amountUsd'},

      {text: 'Сумма', value: 'amountRub'},

      {text: 'Валюта', value: 'currency'},

      {text: 'Банк', value: 'bank'},


        // {text: 'Начислено USDT', value: 'usdt'},


      {text: 'Карта', value: 'card'},
      {text: 'Владелец', value: 'cardowner'},

      {text: 'Мерчант', value: 'user'},
      {text: 'UID', value: 'uid'},

      {text: 'Действия', value: 'actions'},
    ],
  }),

  /*
  [ID заявки] - ID пополнения  из БД
  [Мерчант] - Логин (ну или ID) мерчанта uid
  [Создана] - дата создания
  [Пополнение] - сумма пополнения баланса
  [Сумма] - сумма перевода (для рублевых терминалов Пополнение=Сумме)
  [Банк] - пополнение через какой банк
  [Карта] - карта, на которую пришел платеж. В идеале в рамках 1 столбца - ниже - вывести коммент к этой карте из БД
  [Чек] - файл чека
  [Статус] - Успех / Отмена - других статусов в таблице истории НЕТ
   */

  mounted() {

    let t = this.today();

    this.from = t.from;
    this.to = t.to

  },

  methods: {

    get$ByCountry(country){

      switch (country){
        case "ru" : return "₽"
        case "en" : return "$"
        case "tr" : return "₺"
        case "kz" :
          return "₸"
        case "uz" :
          return "UZS"

        default:
          return "def$"
      }
    },

    get$TickerByCountry(country){

      switch (country){
        case "ru" : return "RUB"
        case "en" : return "USD"
        case "tr" : return "TRY"

        default:
          return "default"
      }

    },

    getCountry() {
      return this.$store.state.user.country
    },

    //------ today
    zeroTime(date){
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0)
      return date;
    },

    lastTime(date){
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      date.setMilliseconds(999)
      return date;
    },

    getPeriod(from, to) {  //emitChanged

      var firstday = this.zeroTime(new Date(from));
      var isoFirstTime = firstday.toISOString()

      var lastday = this.lastTime(new Date(to));
      var isoLastTime = lastday.toISOString()

      return {from: isoFirstTime, to: isoLastTime,   from2:from, to2:to}
    },

    today() {
      this.disableEmitChanged = true
      const today = new Date();

      const n = new Date()
      n.setDate(today.getDate() - 1)

      let from2 = (this.getISODate(new Date(n)))
      let to2 = (this.getISODate(new Date(today)))

      return this.getPeriod(from2,to2)
    },

    getISODate(date) {
      return (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
    },
    //------

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    },

    async repeat(item) {
      /* PATCH
      /api/admin/transaction/:id/callback
      */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${RestApiUrl}/${item.id}/callback`
      fetcher(this.dosnackbar, url, requestOptions)

    },

    async patch(item) {
      /* PATCH
      /api/admin/transaction/:id/reclose
      */

      if (!window.confirm(`Перевести в Completed ? \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,

      };

      let url = `${RestApiUrl}/${item.id}/reclose`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    usdt(item) {


      if (item.status === 'canceled')
        return "--"

      //  USD * (100 - Комисиия)/100

      //Amount * (100 - offer_fee)/100

      if (item.currency === 'RUB')
        return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
      else
        return (item.amount * (100 - item.offer.fee) / 100) || 0


      //
    },

    calcRubAmount(item) {
      if (item.currency === 'RUB')
        return item.amount
      else
        return item.offer.amount
    },

    calcUsdAmount(item) {
      if (item.currency === 'USD')
        return item.amount
      else
        return '--'
    },

    async amount(id, amount) {
      //Изменить сумму
      //app.patch("/api/admin/transaction/:id/amount"
      //req.body.amount - сумма в рублях (float)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${RestApiUrl}/${id}/amount`


      fetcher(this.dosnackbar, url, requestOptions)


    },

    async approve(item) {
      //Одобрение
      //app.put("/api/admin/transaction/:id"

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`


      fetcher(this.dosnackbar, url, requestOptions)


    },

    async del(item) {
      //Удаление заявки
      // app.delete("/api/admin/transaction/:id"

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`


      fetcher(this.dosnackbar, url, requestOptions)


    },


    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },


    fin(val, r = 2) {


      return val === '--' ? '--' : Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
