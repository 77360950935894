import {loginInstance, defaultInstance} from './myaxios';

export const AuthAPI =
    {

        refreshToken(refreshToken) {
            return loginInstance.post('/refreshtoken', JSON.stringify({refreshToken}))
        },

        login(username, password) {
            return loginInstance.post('/login', {username, password})

        }
        ,

        logout() {
            return defaultInstance.post('/logout')

        }

    }
