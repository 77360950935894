<template>

  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-10">
      <h3>История корректировок</h3>
    </div>

    <div>
      <CardSelectWithInactive :initId="initial_id" v-on:selected="on_card_selected"></CardSelectWithInactive>
    </div>

    <div>
      Владелец: {{ card_selected.owner ? card_selected.owner : "--" }}<br>
      Чат: {{ card_selected.chatId }}
    </div>

    <div>
      <v-data-table
          dense
          :items="balanceAdjusters"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':[  50,  100,  200,  -1]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.status="{ item }">           {{item.status}}        </template>
        <template v-slot:item.updatedAt="{ item }">          <DateTimeCell :value="item.updatedAt"></DateTimeCell>        </template>
        <template v-slot:item.value="{ item }"> {{ JSON.stringify(item.value)}}</template>

        <template v-slot:item.user="{ item }"> {{ item.user? item.user.username : "--" }}</template>



      </v-data-table>
    </div>

  </div>
</template>
<script>

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/transaction"

import gql from "graphql-tag";
import {getHeaders} from "../store";
//import {getURLs} from "../store";
import {fetcher} from "../store";
import Snackbar from "./controls/Snackbar.vue";
import CardSelectWithInactive from "./controls/CardSelectWithInactive.vue";
import DateTimeCell from "./controls/DateTimeCell.vue";

export default {

  props: ['cardId'],

  name: 'History',

  components: {
    DateTimeCell,

    CardSelectWithInactive,
    Snackbar
  },

  apollo: {
    balanceAdjusters: {
      query: gql`

query q( $cardId: Int = 1) {
  balanceAdjusters(order_by: {updatedAt: desc}, where: {cardId: {_eq: $cardId}}) {
    id
    status
    type
    updatedAt

    comment
    cardId
    amount

    user {
      username
    }


  }
}

`,

      variables() {
        return {//priority for card in combobox
          cardId: this.card_selected.id
        }
      },

      result({data}) {
        this.balanceAdjusters = data.balanceAdjusters;
      },

       skip() {
         return  ! this.card_is_selected
       }

    },
  },


  data: () => ({
    card_is_selected : false,
    card_selected: {},  //must be {}
    transactionHistories: [],
    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Время', value: 'updatedAt'},
      {text: 'Тип', value: 'type'},
      {text: 'Статус', value: 'status'},
      {text: 'Оператор', value: 'user'},
      {text: 'Количество', value: 'amount'},
      {text: 'Комментарий', value: 'comment'},
    ],
  }),

  computed:{

    initial_id() {//for  selectbox
      return this.cardId ? this.cardId : "0"
    },

  },

  methods: {

    async directRefill(user, card, sum) {
      /* POST
      /api/admin/transaction
     merchantId
     cardId
     amount */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              merchantId: +(user.id),
              cardId: +(card.id),
              amount: +sum

            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    on_card_selected(num, val) {  //must bw fired when init
      this.card_is_selected = true
      this.card_selected = val

    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    onPerodChanged(val) {
      //  console.log('onPerodChanged', val)
      this.from = val.from
      this.to = val.to
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },

    async amount(id, amount) {
      //Изменить сумму
      //app.patch("/api/admin/transaction/:id/amount"
      //req.body.amount - сумма в рублях (float)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${RestApiUrl}/${id}/amount`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async approve(item) {
      //Одобрение
      //app.put("/api/admin/transaction/:id"


      if (!window.confirm(`Подтвердите Одобрение ?! \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};


            `)) {

        return
      }

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PUT',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async del(item) {
      //Удаление заявки
      // app.delete("/api/admin/transaction/:id"


      if (!window.confirm(`Подтвердите УДАЛЕНИЕ ? \n ID: ${item.id};
        Сумма: ${item.offer.amount};
        Мерчант: ${item.user ? item.user.username : '--'};
        Банк: ${item.bank ? item.bank.name : '--'};


            `)) {

        return
      }


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
