var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v(_vm._s(new Date(Date.parse(_vm.value)).toLocaleString())),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }