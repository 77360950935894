<template>

  <v-select
      v-model="val2"
      label="Страна-валюта"
      :items="Countries"


      :item-text="item => ( item.country ) "
      return-object
      outlined
      clearable
  ></v-select>

</template>

<script>

export default {
  name: "CurrencySelect",

  data: function () {
    return {
      dialog: false,
      name:null,
      val2:null,

      Countries:[      {
        "id": 2,
        "country": "tr",
        "currency:":"TRY"
      },
        {
          "id": 1,
          "country": "ru",
          "currency:":"RUB"
        },
        {
          "id": 3,
          "country": "us",
          "currency:":"USD"
        },

        {
          "id": 4,
          "country": "kz",
          "currency:":"KZ"
        },

        {
          "id": 5,
          "country": "uz",
          "currency:":"UZ"
        },

      ],
    }
  },


  watch:{
    val2:function (val){

       // if (val)
        {
          this.$emit('changed', val)
        }
    },
  },




}
</script>

